import React, { useEffect } from 'react';
import contact from '../../assets/images/contact-us.png';
import img1 from '../../assets/images/contact-preview.png';
import instagram from '../../assets/images/small/instagram-img.png';
import whatsapp from '../../assets/images/small/whatsapp-img.png';
import messanger from '../../assets/images/small/messanger-img.png';
import linkedin from '../../assets/images/small/linkdin-img.png';
import { Link } from 'react-router-dom';
import SeoDetails from '../../seo/SeoDetails';
function ContactUs() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <SeoDetails title="mepass | Contact Us"
                description="mepass | Contact Us"
            />
            <div className="w-full h-full md:h-[400px] bg-cover pt-28 bg-right md:pt-[135px] pb-4 md:pb-20" style={{ backgroundImage: `url(${contact})` }}>
                <div className="container ">
                    <h2 className='text-white text-[50px] md:text-[64px] font-body font-semibold h-[240px] flex items-end'>Contact Us</h2>
                </div>
            </div>

            {/* -------------------------------section-start------------------------- */}
            <section className='bg-neutral py-24'>
                <div className="container">
                    <div className='grid grid-cols-1 md:grid-cols-2 gap-8'>
                        <div>
                            <p className='text-white text-base leading-6 font-body font-normal'>Customer satisfaction is key! Contact us for a smooth entertainment booking journey</p>
                            <p className='text-white text-base leading-6 font-body font-normal mt-5'>Your satisfaction is our priority! At Mepass, we're here to make your entertainment booking experience seamless and enjoyable. Reach out to us with any questions or feedback. Let's make your entertainment dreams a reality</p>
                        </div>
                        <div>
                            <img src={img1} alt="" className='w-full' />
                        </div>
                    </div>
                    <div className='grid grid-cols-1 md:grid-cols-2 gap-8 mt-7'>
                        <div className='bg-black p-5 rounded-lg'>
                            <h2 className='text-2xl xl:text-[40px] font-body font-light tracking-[.150em] text-white uppercase'>Ticket & Audience</h2>
                            <p className='text-white text-base leading-6 font-body font-normal mt-3'>Customer satisfaction is key! Contact us for a smooth entertainment booking journey</p>
                            <div className='block lg:flex items-center gap-8 mt-7'>
                                <div>
                                    <p className='text-white font-body font-normal text-base -mb-[4px]'>Mobile Number</p>
                                    <span className='text-white text-sm font-body font-semibold'><i className="fas fa-phone text-[10px] mr-2"></i>9925060619</span>
                                </div>
                                <div className='mt-3 lg:mt-0'>
                                    <p className='text-white font-body font-normal text-base -mb-[4px]'>Email Address</p>
                                    <span className='text-white text-sm font-body font-semibold'><i className="fas fa-envelope text-[12px] mr-2"></i><Link to="mailto:Support@mepass.in">Support@mepass.in</Link></span>
                                </div>
                            </div>
                        </div>
                        <div className='bg-black p-5 rounded-lg'>
                            <h2 className='text-2xl xl:text-[40px] font-body font-light tracking-[.150em] text-white uppercase'>For Business</h2>
                            <p className='text-white text-base font-body font-normal mt-3'>Stay updated with our latest promotions, discounts and special offers.</p>
                            <div className='block lg:flex items-center gap-8 mt-7'>
                                <div>
                                    <p className='text-white font-body font-normal text-base -mb-[4px]'>Mobile Number</p>
                                    <span className='text-white text-sm font-body font-semibold'><i className="fas fa-phone text-[10px] mr-2"></i>9099060633</span>
                                </div>
                                <div className='mt-3 lg:mt-0'>
                                    <p className='text-white font-body font-normal text-base -mb-[4px]'>Email Address</p>
                                    <span className='text-white text-sm font-body font-semibold'><i className="fas fa-envelope text-[12px] mr-2"></i><Link to="mailto:Onboarding@mepass.in">Onboarding@mepass.in</Link></span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='grid grid-cols-1 mt-7'>
                        <div className='bg-black p-5 rounded-lg'>
                            <h2 className='text-2xl xl:text-[40px] font-body font-light tracking-[.150em] text-white uppercase'>Connect Us</h2>
                            <p className='text-white text-base font-body font-normal  mt-3'>Customer satisfaction is key! Contact us for a smooth entertainment booking journey</p>
                            <div className='grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-10 mt-7'>
                                <Link to="https://www.facebook.com/mepassbharat/" target="_blank" className='border border-[#0084FF] py-3 px-10 w-full 2xl:w-72 rounded-lg text-2xl text-[#0084FF] font-body font-normal whitespace-nowrap'><img src={messanger} alt="" className='mr-2 inline' />Message Us</Link>
                                <button className='border border-[#66E066] py-3 px-10 w-full 2xl:w-72 rounded-lg text-2xl text-[#66E066] font-body font-normal whitespace-nowrap'><img src={whatsapp} alt="" className='mr-2 inline' />Send Message</button>
                                <Link to="https://www.linkedin.com/company/mepassbharat/" target="_blank" className='border border-[#1686B0] py-3 px-10 w-full 2xl:w-72 rounded-lg text-2xl text-[#1686B0] font-body font-normal whitespace-nowrap'><img src={linkedin} alt="" className='mr-2 inline' />Message</Link>
                                <Link to="https://www.instagram.com/mepassbharat/" target="_blank" className='border border-[#FF7944] py-3 px-10 w-full 2xl:w-72 rounded-lg text-2xl text-[#FF7944] font-body font-normal whitespace-nowrap'><img src={instagram} alt="" className='mr-2 inline' />Message</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* -------------------------------section-end------------------------- */}








        </>
    );
}

export default ContactUs;
