import { isEmpty } from "lodash";
import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import icon from "../../assets/images/small/percentage-yellow.png";
import { convertToAmPm, convertToDate } from "../../common/commonFunction";
import withRouter from "../../components/Common/withRouter";
import api from "../../constants/api";
import { addUpdateNewData, clearData, getData } from "../../store/AppMaster/actions";
import logo from "../../assets/images/logo.png"
import PaymentOption from "./PaymentOption";
import getPassCalculation from "./PassPaymentCalculation";
import EventDetails from "../../components/PassPurchase/EventDetails";
import EventDetailsRes from "../../components/PassPurchase/EventDetailsRes";

import Select2 from "react-select"
import SelectPass from "../../components/PassPurchase/SelectPass";
import SelectPassRes from "../../components/PassPurchase/SelectPassRes";
import PassOffers from "../../components/PassPurchase/PassOffers";
import PassOffersRes from "../../components/PassPurchase/PassOffersRes";
import SelectDate from "../../components/PassPurchase/SelectDate";
import SelectDateRes from "../../components/PassPurchase/SelectDateRes";
import SeoDetails from "../../seo/SeoDetails";

const PassPurchase = props => {
  const { data } = useSelector(state => ({
    data: state.AppMaster.data,
  }));
  const [passWithQuantity, setPassWithQuantity] = useState([]);
  const [event, setEvent] = useState('');
  const [settings, setSettings] = useState('');
  const [passList, setPassList] = useState([]);
  const [passOffers, setPassOffers] = useState([]);
  const [isApiCall, setIsApiCall] = useState(0);
  const [isDefault, setDefault] = useState(true);
  const [isUpdateData, setIsUpdateData] = useState(false);
  const [isUpdateDateData, setIsUpdateDateData] = useState(false);
  const [quantity, setQuantity] = useState(0);
  const [isProcessedToPay, setIsProcessedToPay] = useState(false);
  const [passPaymentCalculation, setPassPaymentCalculation] = useState([]);
  const [makePaymentError, setMakePaymentError] = useState('');
  const [selectedDate, setSelectedDate] = useState(null);
  const [optionGroupDate, setOptionGroupDate] = useState([]);
  const [dailyPassCount, setDailyPassCount] = useState(0);
  const [sessionPassCount, setSessionPassCount] = useState(0);
  const [promoCode, setPromoCode] = useState("")
  const [isApplyPromoCode, setIsApplyPromoCode] = useState(false)
  const [activeTab, setActiveTab] = useState(1);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isDropdownResOpen, setIsDropdownResOpen] = useState(false);
  const dropdownRef = useRef(null);
  const dropdownResRef = useRef(null);
  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };
  const toggleResDropdown = () => {
    setIsDropdownResOpen(!isDropdownResOpen);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsDropdownOpen(false);
    }
  };
  const handleClickOutsideRes = (event) => {
    if (dropdownResRef.current && !dropdownResRef.current.contains(event.target)) {
      setIsDropdownOpen(false);
    }
  };
  useEffect(() => {
    if (isDropdownOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isDropdownOpen]);

  useEffect(() => {
    if (isDropdownResOpen) {
      document.addEventListener('mousedown', handleClickOutsideRes);
    } else {
      document.removeEventListener('mousedown', handleClickOutsideRes);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isDropdownResOpen]);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  }
  const dispatch = useDispatch();

  useEffect(() => {
    if (isApiCall === 0) {
      getEventDateList();
      setIsApiCall(1);
    }
  }, [isApiCall, props.router.params.event_slug, dispatch, props.router.navigate]);

  const getEventDateList = () => {
    setIsUpdateDateData(true);
    const requestEventDateData = {
      event_slug: props.router.params.event_slug,
    };
    dispatch(getData(requestEventDateData, props.router.navigate, api.getEventDateList));
  }

  const getPassListData = (date_id) => {
    setIsUpdateData(true);
    const requestData = {
      event_slug: props.router.params.event_slug,
      event_date_id: date_id,
    };
    dispatch(clearData());
    dispatch(getData(requestData, props.router.navigate, api.getPassListByDate));
  }

  if (!isEmpty(data) && !isEmpty(data.event) && isUpdateDateData) {
    setEvent(data.event);
    setDailyPassCount(data.passAvailability.dailyPassType);
    setSessionPassCount(data.passAvailability.seasonPassType);
    if (data.passAvailability.dailyPassType <= 0) {
      setActiveTab(2);
    }
    const passDates = [];
    data.eventDates.map(eventDate => {
      if (eventDate.dailyPassType > 0) {
        passDates.push({ label: eventDate.formatted_date, value: eventDate.id });
      }
    });
    setOptionGroupDate(passDates);
    if (data.passAvailability.dailyPassType > 0) {
      let isDefaultDate = false;
      data.eventDates.map((item, index) => {
        if (item.dailyPassType > 0 && !isDefaultDate) {
          setSelectedDate({ label: item.formatted_date, value: item.id });
          getPassListData(item.id);
          isDefaultDate = true;
        }
      });
    } else {
      getPassListData();
    }
    setIsUpdateDateData(false);
  }

  if (!isEmpty(data) && !isEmpty(data.settings) && isUpdateData) {
    setSettings(data.settings);

    if (data.eventPasses.length > 0) {
      data.eventPasses && data.eventPasses.forEach((pass) => {
        pass.percentage = 0;
        if (pass.amount > 0 && pass.amount_after_discount != pass.amount) {
          pass.percentage = Math.round(((pass.amount - pass.amount_after_discount) / pass.amount) * 100);
        }
        pass.quantity = 0;
        pass.PriceDisplay = 0;
        if (pass.pass_type_slug === "cover-mepass" || pass.pass_type_slug === "normal-mepass") {
          pass.event_date_id = (pass.eventPassDates.length > 0) ? pass.eventPassDates[0].event_date_id : "";
          setPassWithQuantity(passWithQuantity => [...passWithQuantity, {
            event_pass_id: pass.id,
            quantity: 0,
            event_date_id: (pass.eventPassDates.length > 0) ? pass.eventPassDates[0].event_date_id : "",
          }]);

        } else {
          setPassWithQuantity(passWithQuantity => [...passWithQuantity, {
            event_pass_id: pass.id,
            quantity: 0,
            event_date_id: '',
          }]);

        }
      });
    }
    setPassList(data.eventPasses);
    setPassOffers(data.offers);
    setPassPaymentCalculation(getPassCalculation(data.eventPasses, promoCode, event, data.settings, data.offers));
    setIsUpdateData(false);
  }

  const handleIncrement = (passId, prevQuantity) => {
    setMakePaymentError("");
    passList.forEach((pass) => {
      if (pass.id === passId) {
        pass.quantity += 1;
      }
    });
    setPassList(passList);

    setPassPaymentCalculation(getPassCalculation(passList, promoCode, event, settings, passOffers));
  };

  const handleDecrement = (passId, prevQuantity) => {
    if (prevQuantity - 1 >= 0) {
      passList.forEach((pass) => {
        if (pass.id === passId) {
          pass.quantity -= 1;
        }
      });
      setPassList(passList);
      setPassPaymentCalculation(getPassCalculation(passList, promoCode, event, settings, passOffers));
    }
  };

  //Payment Code
  // Apply promo code
  const [paymentStatus, setPaymentStatus] = useState("");
  const [orderDetails, setOrderDetails] = useState({})
  const makePayment = () => {
    if (localStorage.getItem('publicMePassUser')) {
      const pass_with_quantity = passList.filter((passWithQuantity) => passWithQuantity.quantity > 0)
      console.log(Math.round(passPaymentCalculation.net_amount));
      if (pass_with_quantity.length > 0) {
        setIsProcessedToPay(true);
        setMakePaymentError("");
      } else {
        setMakePaymentError("Please select atleast one pass to proceed")
      }

    } else {
      //setIsLoginOpen(true);
      setIsProcessedToPay(false);
    }
  }

  const handleDateDropdown = (selectedOption) => {
    setSelectedDate({ label: selectedOption.label, value: selectedOption.value });
    toggleDropdown();
    getPassListData(selectedOption.value);
  }

  const handlePromoCodeApply = () => {
    if (!isEmpty(promoCode)) {
      setPromoCode(promoCode);
      // If Pass Quantity is 0 then set it to remove it from the request
      setPassPaymentCalculation(getPassCalculation(passList, promoCode, event, settings, passOffers));
    }
  }

  if (!isEmpty(data) && !isEmpty(data.makePayment) && isEmpty(orderDetails)) {
    setOrderDetails(data.makePayment)
    if (data.makePayment.net_amount > 0) {
      setPaymentStatus("")
      const userDetail = JSON.parse(
        localStorage.getItem("publicMePassUserDetails"),
      )
      const createOrder = {
        amount: data.makePayment.net_amount,
        currency: "INR",
        description: "Test Transaction",
        order_id: data.makePayment.payment_order_id,
        name: data.makePayment.user_name,
        email: data.makePayment.user_email,
        contact: userDetail.mobile,
      }
      handlePaymentRazorpay(createOrder)
    } else {
      setIsProcessedToPay(false);
      //window.location.reload()
    }
  }
  //  Payment Details
  useEffect(() => {
    const script = document.createElement("script")
    script.src = "https://checkout.razorpay.com/v1/checkout.js"
    script.async = true
    document.body.appendChild(script)
  }, [])

  function getPaymentStatus(payment_id, order_id, signature) {
    setPaymentStatus("")
    const requestData = {
      payment_id: payment_id,
      order_id: order_id,
      signature: signature,
    }
    dispatch(addUpdateNewData(requestData, "", api.CheckPaymentStatus))
  }

  if (
    !isEmpty(data) &&
    !isEmpty(data.paymentStatus) &&
    isEmpty(paymentStatus)
  ) {
    setPaymentStatus(data.paymentStatus)
    if (data.paymentStatus.status === "paid") {
      props.router.navigate(`/payment/${data.paymentStatus.payment_order_id}/thank-you`)
    } else if (data.paymentStatus.status === "created") {
      setTimeout(() => {
        getPaymentStatus(data.paymentStatus.payment_order_id)
      }, 2000)
    } else if (
      data.paymentStatus.status === "attempted" ||
      data.paymentStatus.retry === "No"
    ) {
      setTimeout(() => {
        getPaymentStatus(data.paymentStatus.payment_order_id)
      }, 2000)
    } else if (
      data.paymentStatus.status === "attempted" ||
      data.paymentStatus.retry === "Yes"
    ) {
      const userDetail = JSON.parse(
        localStorage.getItem("publicMePassUserDetails"),
      )
      const createOrder = {
        amount: data.makePayment.totalAmount,
        currency: "INR",
        description: "Test Transaction",
        order_id: data.makePayment.payment_order_id,
        name: data.makePayment.user_name,
        email: data.makePayment.user_email,
        contact: userDetail.mobile,
      }
      handlePaymentRazorpay(createOrder)
    }
  }

  function handlePaymentRazorpay(order) {
    try {
      // Initialize Razorpay payment
      const options = {
        key: process.env.REACT_APP_RAZORPAY_KEY, //  Razorpay API key Get From .env file
        amount: order.amount, // Amount in smallest currency unit (e.g., paise for INR)
        currency: "INR",
        name: process.env.REACT_APP,
        description: order.description,
        image: logo,
        order_id: order.order_id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
        handler: function (response) {
          // Handle payment success
          // You should verify this payment ID & signature in your server.
          getPaymentStatus(
            response.razorpay_payment_id,
            response.razorpay_order_id,
            response.razorpay_signature,
          )
        },
        prefill: {
          name: order.name,
          email: order.email,
          contact: order.contact_number,
        },
        notes: {
          address: "Razorpay Corporate Office",
        },
        theme: {
          color: "#CB2027",
        },
      }
      let paymentObject = new Razorpay(options)
      paymentObject.open()
    } catch (error) {
      console.error("Payment failed", error)
    }
  }

  const processedToPay = () => {
    if (localStorage.getItem('publicMePassUser')) {
      const pass_with_quantity = passWithQuantity.filter((passWithQuantity) => passWithQuantity.quantity > 0)
      setOrderDetails("")
      const requestData = getPassCalculation(passList, promoCode, event, settings, passOffers);
      dispatch(addUpdateNewData(requestData, "", api.MakePayment))
    } else {
      setIsProcessedToPay(false);
      //setIsLoginOpen(true);
    }
  }

  return (
    <>
      <SeoDetails
        title={'Book your Pass Now | ' + event.name}
        description={event.short_description + ' Pass Booking'}
        keywords={event.keywords}
        ogImage={event.image}
      />
      {isProcessedToPay && (
        <PaymentOption
          event={event}
          paymentDetails={passPaymentCalculation}
          passList={passList}
          processedToPay={processedToPay}
          settings={settings}
          setIsProcessedToPay={setIsProcessedToPay}
        ></PaymentOption>
      )}
      {!isProcessedToPay && (
        <div className="bg-neutral pt-32 pb-20  bg-no-repeat">
          <div className="container">

            <div className="hidden md:block">
              <EventDetails event={event} />

              {/* select dates */}
              {optionGroupDate.length > 0 && sessionPassCount > 0 &&
                <div className="bg-[#000000] p-4 mt-7 rounded-lg">
                  <div className="bg-[#131313] p-2 rounded-lg">
                    <div className="flex items-center justify-between">
                      {optionGroupDate.length > 0 &&
                        <button
                          className={`button ${activeTab === 1
                            ? "active-button bg-secondary rounded-md !text-black font-semibold"
                            : ""
                            } py-2 text-white font-body font-normal text-[18px] w-1/2`}
                          onClick={() => handleTabClick(1)}
                        >
                          Daily Pass
                        </button>
                      }
                      {sessionPassCount > 0 &&
                        <button
                          className={`button ${activeTab === 2
                            ? "active-button !bg-secondary rounded-md !text-black font-semibold"
                            : ""
                            } bg-transparent py-2 text-white font-body font-normal text-[18px] w-1/2`}
                          onClick={() => handleTabClick(2)}
                        >
                          Season Pass
                        </button>
                      }
                    </div>
                  </div>
                  {activeTab === 1 &&
                    <>
                      {optionGroupDate.length > 0 &&
                        <SelectDate dropdownRef={dropdownRef} toggleDropdown={toggleDropdown} isDropdownOpen={isDropdownOpen} optionGroupDate={optionGroupDate} selectedDate={selectedDate} handleDateDropdown={handleDateDropdown} activeTab={activeTab} />
                      }
                    </>

                  }
                </div>
              }
              {optionGroupDate.length > 0 && sessionPassCount == 0 &&
                <div className="bg-[#000000] p-4 mt-7 rounded-lg">
                  {optionGroupDate.length > 0 &&
                    <SelectDate dropdownRef={dropdownRef} toggleDropdown={toggleDropdown} isDropdownOpen={isDropdownOpen} optionGroupDate={optionGroupDate} selectedDate={selectedDate} handleDateDropdown={handleDateDropdown} activeTab={activeTab} />
                  }
                </div>
              }
              {/* ----------------card-start--------------- */}
              {!isEmpty(passList) && (
                <>
                  <div className="bg-black p-4 md:p-7 rounded-lg mt-7">
                    {activeTab === 1 && (
                      <>
                        {dailyPassCount > 0 ?
                          <>
                            <h3 className="text-[22px] md:text-[40px] text-white font-body font-light tracking-[0.3rem] uppercase mb-5">
                              Select mepass
                            </h3>
                            {passList.map((pass, index) => (
                              <>
                                {(pass.pass_type_slug === "cover-mepass" || pass.pass_type_slug === "normal-mepass") && (
                                  <SelectPass pass={pass} handleIncrement={handleIncrement} handleDecrement={handleDecrement} activeTab={activeTab} key={index} />
                                )}
                              </>
                            ))}
                          </>
                          :
                          <h3 className="text-[22px] md:text-[30px] text-white text-center font-body font-light tracking-[0.3rem] mb-5">
                            No Daily Pass Available
                          </h3>
                        }
                      </>
                    )}
                    {activeTab === 2 && (
                      <>
                        {sessionPassCount > 0 ?
                          <>
                            <h4 className="text-[22px] md:text-[40px] text-white font-body font-light tracking-[0.3rem] uppercase mb-5">
                              Select mepass
                            </h4>
                            {passList.map((pass, index) => (
                              <>
                                {(pass.pass_type_slug === "season-mepass" || pass.pass_type_slug === "flexible-mepass") && (
                                  <SelectPass pass={pass} handleIncrement={handleIncrement} handleDecrement={handleDecrement} activeTab={activeTab} key={index} />
                                )}
                              </>
                            ))}
                          </>
                          :
                          <h4 className="text-[22px] md:text-[30px] text-white text-center font-body font-light tracking-[0.3rem] mb-5">
                            No Session Pass Available
                          </h4>
                        }
                      </>
                    )}

                  </div>
                </>

              )}

              {/* ----------------card-start--------------- */}
              {!isEmpty(passOffers) && (
                <PassOffers passPaymentCalculation={passPaymentCalculation} passOffers={passOffers} icon={icon} handlePromoCodeApply={handlePromoCodeApply} promoCode={promoCode} setPromoCode={setPromoCode} />
              )}
              {/* ----------------card-end---------------- */}

              {/* ----------------card-start--------------- */}
              <div className="bg-black p-5 md:p-7 rounded-lg mt-7">
                <div className="border-y border-[#F6F6F6] py-5 my-5">
                  <div className="flex items-center justify-between mb-2">
                    <p className="text-base text-[#DCDCDC] font-body font-normal">
                      GST
                    </p>
                    <p className="text-base text-[#DCDCDC] font-body font-medium">
                      ₹{passPaymentCalculation.tax_amount ? passPaymentCalculation.tax_amount : 0}
                    </p>
                  </div>
                  <div className="flex items-center justify-between mb-2">
                    <p className="text-base text-[#DCDCDC] font-body font-normal">
                      Convenience Charge
                    </p>
                    <p className="text-base text-[#DCDCDC] font-body font-medium">
                      ₹{passPaymentCalculation.convenience_charge ? passPaymentCalculation.convenience_charge : 0}
                    </p>
                  </div>
                  {passPaymentCalculation.get_y && passPaymentCalculation.get_y.length > 0 && (
                    <div className="flex items-center justify-between mb-2">
                      <p className="text-base text-[#DCDCDC] font-body font-normal">
                        {passPaymentCalculation.get_y[0]['pass_detail']['name']}
                      </p>
                      <p className="text-base text-[#DCDCDC] font-body font-medium">
                        {passPaymentCalculation.get_y[0]['quantity']} MEPASS FREE
                      </p>
                    </div>
                  )}
                </div>
                <div className="block md:flex items-center justify-between text-center md:text-left">
                  <div className="text-center md:text-left">
                    <span className="text-3xl text-secondary font-body font-semibold uppercase">
                      ₹ {passPaymentCalculation.net_amount ? passPaymentCalculation.net_amount : 0}
                    </span>
                    <p className="text-xl text-white font-body font-normal mt-2">
                      Amount
                    </p>
                  </div>
                  {localStorage.getItem('publicMePassUser') ? (
                    <>
                      {makePaymentError && (
                        <p className="text-red-500 text-sm font-body font-normal mt-2">
                          {makePaymentError}
                        </p>
                      )}
                      {passPaymentCalculation.net_amount > 0 && (
                        <button
                          onClick={
                            () => makePayment()
                          }
                          className="bg-primary border border-primary  text-white text-base font-normal tracking-[.25em] py-3 px-8 rounded font-body  hover:bg-white hover:text-primary duration-1000 ease-in-out uppercase mt-3 md:mt-0">
                          Make Payment
                        </button>
                      )}
                    </>

                  ) : (
                    <button
                      onClick={() => props.router.navigate('/login', { state: { is_login_page: 'Yes' } })}
                      className="bg-primary border border-primary  text-white text-base font-normal tracking-[.25em] py-3 px-8 rounded font-body  hover:bg-white hover:text-primary duration-1000 ease-in-out uppercase mt-3 md:mt-0">
                      Login
                    </button>
                  )}
                </div>
              </div>
              {/* ----------------card-end---------------- */}
            </div>

            {/* ---------------------------------------------mobile-view-start------------------------ */}

            <div className="md:hidden block">
              <EventDetailsRes event={event} />
              {/* select dates */}
              {optionGroupDate.length > 0 && sessionPassCount > 0 &&
                <div className="bg-[#000000] p-4 mt-7 rounded-lg">
                  <div className="bg-[#131313] p-2 rounded-lg">
                    <div className="flex items-center justify-between">
                      <button
                        className={`button ${activeTab === 1
                          ? "active-button bg-secondary rounded-md !text-black font-semibold"
                          : ""
                          } py-2 text-white font-body font-normal text-[18px] w-1/2`}
                        onClick={() => handleTabClick(1)}
                      >
                        Daily Pass
                      </button>
                      <button
                        className={`button ${activeTab === 2
                          ? "active-button !bg-secondary rounded-md !text-black font-semibold"
                          : ""
                          } bg-transparent py-2 text-white font-body font-normal text-[18px] w-1/2`}
                        onClick={() => handleTabClick(2)}
                      >
                        Session Pass
                      </button>
                    </div>
                  </div>
                  {activeTab === 1 &&
                    <>
                      {optionGroupDate.length > 0 &&
                        <SelectDateRes dropdownResRef={dropdownResRef} toggleResDropdown={toggleResDropdown} isDropdownResOpen={isDropdownResOpen} optionGroupDate={optionGroupDate} selectedDate={selectedDate} handleDateDropdown={handleDateDropdown} />
                      }
                    </>
                  }
                </div>
              }
              {optionGroupDate.length > 0 && sessionPassCount == 0 &&
                <div className="bg-[#000000] p-4 mt-7 rounded-lg">
                  {optionGroupDate.length > 0 &&
                    <SelectDateRes dropdownResRef={dropdownResRef} toggleResDropdown={toggleResDropdown} isDropdownResOpen={isDropdownResOpen} optionGroupDate={optionGroupDate} selectedDate={selectedDate} handleDateDropdown={handleDateDropdown} />
                  }
                </div>
              }
              <div className="mt-8">
                <h3 className="text-[22px] text-white font-body font-light tracking-[0.3rem] uppercase mb-5">
                  Select mepass
                </h3>
                {!isEmpty(passList) && (
                  <div className="bg-black p-4 md:p-7 rounded-lg mt-7">
                    <>
                      {activeTab === 1 && (
                        <>
                          {dailyPassCount > 0 ?
                            <>
                              {passList.map((pass, index) => (
                                <>
                                  {(pass.pass_type_slug === "cover-mepass" || pass.pass_type_slug === "normal-mepass") && (
                                    <SelectPassRes pass={pass} handleIncrement={handleIncrement} handleDecrement={handleDecrement} activeTab={activeTab} key={index} />
                                  )}
                                </>
                              ))}

                            </>
                            :
                            <h3 className="text-[22px] md:text-[30px] text-white text-center font-body font-light tracking-[0.3rem] mb-5">
                              No Daily Pass Available
                            </h3>
                          }
                        </>
                      )}
                      {activeTab === 2 && (
                        <>
                          {sessionPassCount > 0 ?
                            <>
                              {passList.map((pass, index) => (
                                <>
                                  {(pass.pass_type_slug === "season-mepass" || pass.pass_type_slug === "flexible-mepass") && (
                                    <SelectPassRes pass={pass} handleIncrement={handleIncrement} handleDecrement={handleDecrement} activeTab={activeTab} key={index} />
                                  )}
                                </>
                              ))}
                            </>
                            :
                            <h3 className="text-[22px] md:text-[30px] text-white text-center font-body font-light tracking-[0.3rem] mb-5">
                              No Session Pass Available
                            </h3>
                          }
                        </>
                      )}
                    </>
                  </div>
                )}
              </div>
              {!isEmpty(passOffers) && (
                <PassOffersRes passPaymentCalculation={passPaymentCalculation} passOffers={passOffers} icon={icon} handlePromoCodeApply={handlePromoCodeApply} promoCode={promoCode} setPromoCode={setPromoCode} />
              )}

              <div className="mt-7">
                {makePaymentError && (
                  <p className="text-red-500 text-sm font-body font-normal mt-2">
                    {makePaymentError}
                  </p>
                )}
                {passPaymentCalculation.convenience_charge > 0 && (
                  <span className="text-sm text-white font-body block md:flex uppercase">
                    Convenience Charge
                    ₹ {passPaymentCalculation.convenience_charge ? passPaymentCalculation.convenience_charge : 0}
                  </span>
                )}
              </div>
              <div className="bg-black p-5 md:p-7 rounded-lg mt-7 mb-7">
                <div className="border-y border-[#F6F6F6] py-5 my-5">
                  <div className="flex items-center justify-between mb-2">
                    <p className="text-base text-[#DCDCDC] font-body font-normal">
                      GST
                    </p>
                    <p className="text-base text-[#DCDCDC] font-body font-medium">
                      ₹{passPaymentCalculation.tax_amount ? passPaymentCalculation.tax_amount : 0}
                    </p>
                  </div>
                  <div className="flex items-center justify-between mb-2">
                    <p className="text-base text-[#DCDCDC] font-body font-normal">
                      Convenience Charge
                    </p>
                    <p className="text-base text-[#DCDCDC] font-body font-medium">
                      ₹{passPaymentCalculation.convenience_charge ? passPaymentCalculation.convenience_charge : 0}
                    </p>
                  </div>
                  {passPaymentCalculation.get_y && passPaymentCalculation.get_y.length > 0 && (
                    <div className="flex items-center justify-between mb-2">
                      <p className="text-base text-[#DCDCDC] font-body font-normal">
                        {passPaymentCalculation.get_y[0]['pass_detail']['name']}
                      </p>
                      <p className="text-base text-[#DCDCDC] font-body font-medium">
                        {passPaymentCalculation.get_y[0]['quantity']} MEPASS FREE
                      </p>
                    </div>
                  )}
                </div>
              </div>
              <div className="flex items-center justify-between ">
                <div className="">
                  <span className="text-3xl text-secondary font-body font-semibold uppercase">
                    ₹ {passPaymentCalculation.net_amount ? passPaymentCalculation.net_amount : 0}
                  </span>
                  <p className="text-base text-white font-body font-normal mt-1">
                    Amount
                  </p>
                </div>
                <div className="flex items-center justify-between ">
                  {localStorage.getItem('publicMePassUser') ? (
                    <>
                      {passPaymentCalculation.net_amount > 0 && (
                        <button
                          onClick={
                            () => makePayment()
                          }
                          className="bg-primary border border-primary  text-white text-sm font-normal tracking-[.25em] py-3 px-4 rounded font-body  hover:bg-white hover:text-primary duration-1000 ease-in-out uppercase mt-3 md:mt-0">
                          Make Payment
                        </button>
                      )}
                    </>
                  ) : (
                    <button
                      onClick={() => props.router.navigate('/login', { state: { is_login_page: 'Yes' } })}
                      className="bg-primary border border-primary  text-white text-base font-normal tracking-[.25em] py-3 px-8 rounded font-body  hover:bg-white hover:text-primary duration-1000 ease-in-out uppercase mt-3 md:mt-0">
                      Login
                    </button>
                  )}
                </div>
              </div>

            </div>

            {/* ---------------------------------------------mobile-view-end------------------------ */}
          </div>
        </div >
      )}
    </>
  );
}

export default withRouter(PassPurchase);