
import { useFormik } from "formik"
import { isEmpty } from "lodash"
import React, { useEffect, useState } from "react"
import {
    GoogleReCaptcha,
    GoogleReCaptchaProvider,
} from "react-google-recaptcha-v3"
import { useDispatch, useSelector } from "react-redux"
import * as Yup from "yup"
import withRouter from "../../components/Common/withRouter"
import LoginPage from "../../components/Login/Login"
import api from "../../constants/api"
import { addUpdateNewData, getData, loginUser } from "../../store/AppMaster/actions"
import SeoDetails from "../../seo/SeoDetails";
const Login = props => {
    const { data } = useSelector(state => ({
        data: state.AppMaster.data,
    }))
    const { loading } = useSelector(state => ({
        loading: state.AppMaster.loading,
    }))

    const dispatch = useDispatch()
    const [userDetails, setUserDetails] = useState('')
    const [mobileNumber, setMobileNumber] = useState("")
    const [isOtpSent, setIsOtpSent] = useState(false)
    const [recaptchaResponse, setRecaptchaResponse] = useState("")
    const [refreshReCaptcha, setRefreshReCaptcha] = useState(false)
    const [isCheckMobile, setIsCheckMobile] = useState(false)
    useEffect(() => {
        setTimeout(() => {
            setRecaptchaResponse("")
        }, 60000)
    }, [recaptchaResponse])

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            mobile: "",
        },
        validationSchema: Yup.object({
            mobile: Yup.string()
                .required("Please Enter Your Mobile")
                .length(10, "Mobile number should be exactly 10 digits"),
        }),
        onSubmit: values => {
            setIsCheckMobile(true)
            const checkEmail = {
                mobile: values.mobile,
            }
            setMobileNumber(values.mobile)
            dispatch(getData(checkEmail, props.router.navigate, api.checkMobile))
        },
    })

    if (!isEmpty(data) && !isEmpty(data.Sta) && !isOtpSent) {
        setIsOtpSent(true)
        dispatch(getData({ mobile: mobileNumber }, "", api.sendOtp))
    }

    if (!isEmpty(data) && data.STATUS_CODE === 200 && !isOtpSent && isCheckMobile) {
        setIsOtpSent(true)
        setIsCheckMobile(false)
        dispatch(getData({ mobile: mobileNumber }, "", api.sendOtp))
    }

    if (!isEmpty(data) && data.STATUS_CODE === 400 && !isOtpSent && isCheckMobile) {
        validation.setFieldError("mobile", data.MESSAGE || "Unknown error");
        setIsCheckMobile(false);
    }


    const validationOtp = useFormik({
        enableReinitialize: true,

        initialValues: {
            otp1: "",
            otp2: "",
            otp3: "",
            otp4: "",
            otp5: "",
            otp6: "",
            mobile: mobileNumber,
        },
        validationSchema: Yup.object({
            otp1: Yup.string().required("Please Enter Your OTP"),
            otp2: Yup.string().required("Please Enter Your OTP"),
            otp3: Yup.string().required("Please Enter Your OTP"),
            otp4: Yup.string().required("Please Enter Your OTP"),
            otp5: Yup.string().required("Please Enter Your OTP"),
            otp6: Yup.string().required("Please Enter Your OTP"),
        }),
        onSubmit: async values => {

            const requestData = {
                mobile: validation.values.mobile,
                otp:
                    values.otp1 +
                    values.otp2 +
                    values.otp3 +
                    values.otp4 +
                    values.otp5 +
                    values.otp6,
                gRecaptchaResponse: recaptchaResponse,
            }
            dispatch(loginUser(requestData, props.router.navigate, api.login))
        },
    })

    const resendOtp = () => {
        dispatch(addUpdateNewData({ mobile: mobileNumber }, "", api.sendOtp))
    }

    if (!loading && validation.isSubmitting) {
        validation.isSubmitting = false
    }
    if (!loading && validationOtp.isSubmitting) {
        validationOtp.isSubmitting = false
    }

    if (!isEmpty(data) && !isEmpty(data.userDetails) && isEmpty(userDetails)) {
        setUserDetails(data.userDetails)
        if (props.router.location.state && props.router.location.state.previousPage == "Login") {
            props.router.navigate('/home')
        } else {
            props.router.navigate(-2)
        }

    }
    return (
        <>
            <SeoDetails title="Mepass - Login"
                description="Mepass - Login"
            />
            <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}>
                <LoginPage
                    validation={validation}
                    isOtpSent={isOtpSent}
                    validationOtp={validationOtp}
                    resendOtp={resendOtp}
                    recaptchaResponse={recaptchaResponse}
                    props={props}
                />
                {isEmpty(recaptchaResponse) && (
                    <GoogleReCaptcha
                        refreshReCaptcha={refreshReCaptcha}
                        setRefreshReCaptcha={setRefreshReCaptcha}
                        onVerify={token => {
                            setRecaptchaResponse(token)
                        }}
                    />
                )}
            </GoogleReCaptchaProvider>

        </>
    );
}

export default withRouter(Login)