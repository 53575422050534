import React from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
// import required modules
import { Autoplay, Navigation, Pagination, Mousewheel, Keyboard } from 'swiper/modules';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import icon from "../../assets/images/small/small-star.png";
import icon2 from "../../assets/images/small/percentage-red.png"
import banner from "../../assets/images/banner.png";
import bannerImageDefault from '../../assets/images/default/event-image.jpg'
import offer from "../../assets/images/Icons.png";
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { convertToAmPm, convertToDate } from '../../common/commonFunction';
import { Link } from 'react-router-dom';

const Banner = ({ events, props, index }) => {
  return (
    <Swiper
      cssMode={true}
      mousewheel={true}
      keyboard={true}
      autoplay={{
        delay: 2500,
        disableOnInteraction: false,
      }}

      modules={[Autoplay, Pagination, Mousewheel, Keyboard]}
      className="mySwiper banner-slider"
    >
      {events && events.length > 0 ? (
        <>
          {events && events.map((singleEvent, index) => {
            return (
              <>
                {!isEmpty(singleEvent.event) ? (
                  <SwiperSlide>
                    <div
                      className="relative w-full min-h-[620px] md:min-h-full md:h-full bg-cover pt-28 md:pt-[135px] pb-10 md:pb-20"
                    >
                      <div
                        className="absolute inset-0 -z-10"
                        style={{
                          backgroundImage: `url(${singleEvent && singleEvent.event && singleEvent.event.banner_image ? singleEvent.event.banner_image : bannerImageDefault})`,
                          filter: 'blur(30px)',
                          opacity: '0.5px',
                          backgroundSize: 'cover',
                          backgroundPosition: 'center',
                          backgroundRepeat: 'no-repeat',
                        }}
                      ></div>
                      <div
                        className="absolute inset-0 bg-black -z-10"
                        style={{ opacity: 0.5 }}
                      ></div>
                      <div className="container">
                        <div className='block md:hidden mb-3'>
                          <div className="flex items-center justify-between gap-5">
                            <h1 className="flex-1 text-base font-body font-medium text-secondary">
                              {singleEvent && singleEvent.event && singleEvent.event.name}
                            </h1>
                            {singleEvent && singleEvent.event && singleEvent.event.rating > 0 ? (
                              <div className='flex-none'>
                                <div className="bg-secondary rounded-full py-2 px-4 flex items-center justify-center gap-2">
                                  <img src={icon} alt="" className="inline-block" />
                                  <p className="text-primary font-body font-semibold text-[24px]">
                                    {singleEvent && singleEvent.event && singleEvent.event.rating}
                                  </p>
                                </div>
                              </div>
                            ) : ''}
                          </div>
                        </div>
                        <div className='block md:hidden '>
                          <Link to={"/events/" + singleEvent.event.slug}>
                            <h2 className="text-xl font-body font-semibold text-white mb-3 line-clamp-2">
                              #{singleEvent && singleEvent.event && index + 1} {singleEvent && singleEvent.event && singleEvent.event.name}
                            </h2>
                          </Link>
                        </div>
                        <div className='grid grid-cols-2 xl:grid-cols-3 gap-5 md:gap-10 lg:gap-32 mt-5 md:mt-0 items-end md:items-center'>
                          <div className='col-span-1 xl:col-span-2'>
                            <div className='hidden md:block'>
                              <div className="flex items-center justify-between gap-5">
                                <h1 className="text-2xl lg:text-4xl font-body font-medium text-secondary">
                                  {singleEvent && singleEvent.event && singleEvent.event.name}
                                </h1>
                                {singleEvent && singleEvent.event && singleEvent.event.rating > 0 ? (
                                  <div className="bg-secondary rounded-full py-2 px-4 flex items-center justify-center gap-2">
                                    <img src={icon} alt="" className="inline-block" />
                                    <p className="text-primary font-body font-semibold text-[24px]">
                                      {singleEvent && singleEvent.event && singleEvent.event.rating}
                                    </p>
                                  </div>
                                ) : ''}
                              </div>
                            </div>
                            <Link to={"/events/" + singleEvent.event.slug}>
                              <h2 className="text-3xl lg:text-6xl font-body font-semibold text-white pt-3 hidden md:block">
                                #{singleEvent && singleEvent.event && index + 1} {singleEvent && singleEvent.event && singleEvent.event.name}
                              </h2>
                            </Link>
                            <div className="block md:flex gap-5 md:pt-5">
                              <h4 className="text-base md:text-[20px] text-white font-normal font-body">
                                <i className="fas fa-calendar text-[16px] mr-2"></i>
                                {!isEmpty(singleEvent && singleEvent.event && singleEvent.event.dates) && singleEvent && singleEvent.event && singleEvent.event.dates[0] ? singleEvent && singleEvent.event && convertToDate(singleEvent.event.dates[0]['date']) : ''}
                              </h4>
                              {singleEvent && singleEvent.event && singleEvent.event && !isEmpty(singleEvent && singleEvent.event && singleEvent.event.dates) && singleEvent && singleEvent.event && singleEvent.event.dates[0] && singleEvent && singleEvent.event && singleEvent.event.dates[0]['start_time'] ?
                                <h5 className="text-base md:text-[20px] text-white font-normal font-body mt-2 md:mt-0">
                                  <i className="fa fa-clock text-[16px] mr-2"></i>{singleEvent && singleEvent.event && convertToAmPm(singleEvent.event.dates[0]['start_time'])}
                                </h5>
                                : ''}
                            </div>
                            {singleEvent && singleEvent.event && singleEvent.event.no_of_ratings > 0 ? (
                              <h6 className="text-base md:text-[20px] text-white font-normal font-body block md:hidden mt-2">
                                <i className="fas fa-user-friends mr-2"></i>
                                80 k
                              </h6>
                            ) : ''}

                            <h6 className="text-base md:text-[20px] text-white font-normal font-body mt-2 md:mt-4 line-clamp-2 lg:line-clamp-none">
                              {singleEvent && singleEvent.event && singleEvent.event.address ? <i className="fas fa-map-marker-alt mr-2"></i> : ''}
                              {singleEvent && singleEvent.event && singleEvent.event.address ? singleEvent && singleEvent.event && singleEvent.event.address : ''}
                            </h6>
                            <div className='md:block hidden mt-3'>
                              {singleEvent && singleEvent.event && singleEvent.event.feature > 0 ? (
                                <button className="bg-secondary border border-secondary text-primary text-sm font-medium py-1 px-2 rounded inline-block mr-3">
                                  <img src={icon} alt='icon' className='inline-block mr-2 mb-1 size-4' />
                                  Featured
                                </button>
                              ) : ''}
                              {singleEvent && singleEvent.event && singleEvent.event.offer_available > 0 ? (
                                <button className="bg-secondary border border-secondary text-primary text-sm font-medium py-1 px-2 rounded inline-block">
                                  <img src={icon2} alt='icon' className='inline-block mr-2 mb-1 size-4' />
                                  Offer Available
                                </button>
                              ) : ''}
                            </div>
                            <p className="font-normal font-body text-base text-white w-full xl:w-[737px] mt-3 leading-6 line-clamp-2 hidden md:block">
                              {singleEvent && singleEvent.event && singleEvent.event.short_description ? singleEvent && singleEvent.event && singleEvent.event.short_description : ''}
                            </p>
                            <div className='block md:hidden mt-4 mb-6'>
                              {singleEvent && singleEvent.event && singleEvent.event.feature > 0 ? (
                                <button className="bg-secondary border border-secondary text-primary text-sm font-medium py-1 px-2 rounded mb-4">
                                  <img src={icon} alt='icon' className='inline-block mr-2 mb-1 size-4' />
                                  Featured
                                </button>
                              ) : ''}
                              {singleEvent && singleEvent.event && singleEvent.event.offer_available > 0 ? (
                                <button className="bg-secondary border border-secondary text-primary text-sm font-medium py-1 px-2 rounded block">
                                  <img src={icon2} alt='icon' className='inline-block mr-2 mb-1 size-4' />
                                  Offer Available
                                </button>
                              ) : ''}
                            </div>
                            {singleEvent && singleEvent.event && singleEvent.event.price_without_tax > 0 ? (
                              <div className="flex items-center justify-between mt-0 md:mt-5">
                                <h4 className="text-lg lg:text-[32px] font-normal text-white font-body">
                                  Price
                                </h4>
                                <div className='text-end'>
                                  <div className='flex justify-end'>
                                    {singleEvent.event.original_price > 0 && singleEvent.event.original_price != singleEvent.event.price_without_tax ? (
                                      <h5 className="text-sm md:text-lg font-medium text-[#c8c8c8] md:mr-3 hidden md:block">
                                        <del>₹ {singleEvent && singleEvent.event && singleEvent.event.original_price ? singleEvent && singleEvent.event && singleEvent.event.original_price : ''}</del>
                                      </h5>
                                    ) : ''}
                                    <h5 className="text-xl lg:text-3xl font-semibold text-secondary font-body">
                                      <b>₹ {singleEvent && singleEvent.event && singleEvent.event.price_without_tax ? singleEvent && singleEvent.event && singleEvent.event.price_without_tax : ''}</b>
                                    </h5>

                                  </div>
                                  {singleEvent.event.original_price > 0 && singleEvent.event.original_price != singleEvent.event.price_without_tax ? (
                                    <h5 className="text-sm md:text-lg font-medium text-[#c8c8c8] inline-block md:hidden">
                                      <del>₹ {singleEvent && singleEvent.event && singleEvent.event.original_price ? singleEvent && singleEvent.event && singleEvent.event.original_price : ''}</del>
                                    </h5>
                                  ) : ''}
                                  {singleEvent.event.percentage > 0 &&
                                    <p className='text-sm md:text-lg font-medium text-[#388e3c] ml-3 inline-block '>{singleEvent.event.percentage}% off</p>
                                  }
                                </div>
                              </div>
                            ) : ''}
                            <div className="flex items-center gap-2 mt-5">


                              {
                                singleEvent.event.pass_created === true && singleEvent.event.pass_available === true ? (
                                  <Link to={`/events/${singleEvent.event.slug}/book-tickets`} className="bg-primary border border-primary text-center text-white text-base font-normal tracking-[.25em] py-2 px-3 md:px-8 rounded w-full lg:w-[213px] font-body hover:bg-white hover:text-primary duration-1000 ease-in-out uppercase block">
                                    Book
                                  </Link>
                                ) : singleEvent.event.pass_created === false ? (
                                  <Link to={`/events/${singleEvent.event.slug}`} className="bg-primary border border-primary text-white text-base text-center font-normal tracking-[.25em] py-2 px-3 md:px-8 rounded w-full lg:w-[213px] font-body hover:bg-white hover:text-primary duration-1000 ease-in-out uppercase block">
                                    view Event
                                  </Link>
                                ) : singleEvent.event.pass_available === false ? (
                                  <button className="bg-primary border border-primary text-white text-base font-normal tracking-[.25em] py-2 px-3 md:px-8 rounded w-full lg:w-[213px] font-body hover:bg-white hover:text-primary duration-1000 ease-in-out uppercase">
                                    Sold
                                  </button>
                                ) : null
                              }
                              {singleEvent && singleEvent.event && singleEvent.event.offer_available ? (
                                <button className="bg-inherit text-white text-sm md:text-[16px] font-normal tracking-[.25em] w-full lg:w-[213px] text-nowrap py-2.5 px-4 rounded font-body hidden md:inline-block">
                                  <img src={offer} className="inline-block mr-2" alt="" />
                                  {singleEvent.event.discount_type === 'Percentage' ? (
                                    <span>{singleEvent && singleEvent.event && singleEvent.event.discount ? singleEvent && singleEvent.event && singleEvent.event.discount : ''}% Offer</span>
                                  ) : (
                                    <span>₹ {singleEvent && singleEvent.event && singleEvent.event.offer ? singleEvent && singleEvent.event && singleEvent.event.offer : ''} Offer</span>
                                  )}

                                </button>
                              ) : ''}
                            </div>
                          </div>
                          <div>
                            <Link to={"/events/" + singleEvent.event.slug}>
                              <img src={singleEvent && singleEvent.event && singleEvent.event.banner_image ? singleEvent && singleEvent.event && singleEvent.event.banner_image : bannerImageDefault} className="w-full rounded-xl aspect-[9/16] object-fill" alt="" />
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                ) : ''}
              </>
            )
          })}
        </>
      ) : ''}
    </Swiper>
  );

}
Banner.propTypes = {
  events: PropTypes.array,
};
export default Banner;
