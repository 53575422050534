import React from "react";
import { Link } from "react-router-dom";

const SkeletonLoaderEvent = () => {
    return (
        <>
            <div className="bg-neutral py-12">
                <div className="container">
                    <div className="grid grid-cols-2 lg:grid-cols-3 gap-5">
                        <div className="group bg-[#212121] border border-[#212121] hover:bg-gradient-to-b from-[#757575] to-[#0F0F0F] p-2 md:p-[15px] rounded-lg transition-all duration-1000 ease-in-out hover:translate-y-1 hover:border hover:border-primary">
                            <div className="flex items-center justify-between gap-3 md:gap-5">
                                <h1 className="flex-1 bg-slatet h-3 w-full rounded"></h1>
                                <div className="flex-none">
                                    <div className="bg-slatet py-2 px-4 rounded-full">
                                    </div>
                                </div>
                            </div>
                            <div className="md:block hidden">
                                <div className="bg-slatet h-3 w-full rounded mt-2"></div>
                            </div>
                            <div className="md:hidden block mt-3">
                                <div className="flex items-center gap-1">
                                    <div className="bg-slatet w-[32px] h-[30px] flex rounded-md p-2"></div>
                                    <div className="bg-slatet w-[32px] h-[30px] flex rounded-md p-2"></div>
                                </div>
                            </div>
                            <div className="hidden md:block">
                                <div className="flex items-center justify-between mt-3">
                                    <div className="h-8 bg-slatet rounded w-full md:w-48 inline-flex">
                                    </div>
                                    <div className="flex items-center gap-1">
                                        <div className="bg-slatet w-[32px] h-[30px] flex rounded-md p-2"></div>
                                        <div className="bg-slatet w-[32px] h-[30px] flex rounded-md p-2"></div>
                                    </div>
                                </div>
                            </div>
                            <div className="relative mt-4">
                                <div className="bg-slatet w-full rounded aspect-[9/16]"></div>
                            </div>
                            <div className="flex items-center justify-end md:justify-between md:gap-3 my-3 md:px-[10px] group-hover:py-2 duration-1000 ease-in-out">
                                <div className="h-10 bg-slatet rounded-3xl w-full md:w-48 hidden md:inline-block"></div>
                                <div className="text-end">
                                    <div className="bg-slatet h-2 w-full rounded"></div>
                                    <div className="flex items-center mt-2">
                                        <div className="bg-slatet h-2 w-16 rounded flex-1"></div>
                                        <div className="bg-slatet h-2 w-16 rounded ml-3 flex-1"></div>
                                    </div>
                                </div>
                            </div>

                            <Link to="">
                                <div className="h-10 bg-slatet rounded w-full inline-flex"></div>
                            </Link>
                        </div>
                        <div className="group bg-[#212121] border border-[#212121] hover:bg-gradient-to-b from-[#757575] to-[#0F0F0F] p-2 md:p-[15px] rounded-lg transition-all duration-1000 ease-in-out hover:translate-y-1 hover:border hover:border-primary">
                            <div className="flex items-center justify-between gap-3 md:gap-5">
                                <h1 className="flex-1 bg-slatet h-3 w-full rounded"></h1>
                                <div className="flex-none">
                                    <div className="bg-slatet py-2 px-4 rounded-full">
                                    </div>
                                </div>
                            </div>
                            <div className="md:block hidden">
                                <div className="bg-slatet h-3 w-full rounded mt-2"></div>
                            </div>
                            <div className="md:hidden block mt-3">
                                <div className="flex items-center gap-1">
                                    <div className="bg-slatet w-[32px] h-[30px] flex rounded-md p-2"></div>
                                    <div className="bg-slatet w-[32px] h-[30px] flex rounded-md p-2"></div>
                                </div>
                            </div>
                            <div className="hidden md:block">
                                <div className="flex items-center justify-between mt-3">
                                    <div className="h-8 bg-slatet rounded w-full md:w-48 inline-flex">
                                    </div>
                                    <div className="flex items-center gap-1">
                                        <div className="bg-slatet w-[32px] h-[30px] flex rounded-md p-2"></div>
                                        <div className="bg-slatet w-[32px] h-[30px] flex rounded-md p-2"></div>
                                    </div>
                                </div>
                            </div>
                            <div className="relative mt-4">
                                <div className="bg-slatet w-full rounded aspect-[9/16]"></div>
                            </div>
                            <div className="flex items-center justify-end md:justify-between md:gap-3 my-3 md:px-[10px] group-hover:py-2 duration-1000 ease-in-out">
                                <div className="h-10 bg-slatet rounded-3xl w-full md:w-48 hidden md:inline-block"></div>
                                <div className="text-end">
                                    <div className="bg-slatet h-2 w-full rounded"></div>
                                    <div className="flex items-center mt-2">
                                        <div className="bg-slatet h-2 w-16 rounded flex-1"></div>
                                        <div className="bg-slatet h-2 w-16 rounded ml-3 flex-1"></div>
                                    </div>
                                </div>
                            </div>

                            <Link to="">
                                <div className="h-10 bg-slatet rounded w-full inline-flex"></div>
                            </Link>
                        </div>
                        <div className="group bg-[#212121] border border-[#212121] hover:bg-gradient-to-b from-[#757575] to-[#0F0F0F] p-2 md:p-[15px] rounded-lg transition-all duration-1000 ease-in-out hover:translate-y-1 hover:border hover:border-primary">
                            <div className="flex items-center justify-between gap-3 md:gap-5">
                                <h1 className="flex-1 bg-slatet h-3 w-full rounded"></h1>
                                <div className="flex-none">
                                    <div className="bg-slatet py-2 px-4 rounded-full">
                                    </div>
                                </div>
                            </div>
                            <div className="md:block hidden">
                                <div className="bg-slatet h-3 w-full rounded mt-2"></div>
                            </div>
                            <div className="md:hidden block mt-3">
                                <div className="flex items-center gap-1">
                                    <div className="bg-slatet w-[32px] h-[30px] flex rounded-md p-2"></div>
                                    <div className="bg-slatet w-[32px] h-[30px] flex rounded-md p-2"></div>
                                </div>
                            </div>
                            <div className="hidden md:block">
                                <div className="flex items-center justify-between mt-3">
                                    <div className="h-8 bg-slatet rounded w-full md:w-48 inline-flex">
                                    </div>
                                    <div className="flex items-center gap-1">
                                        <div className="bg-slatet w-[32px] h-[30px] flex rounded-md p-2"></div>
                                        <div className="bg-slatet w-[32px] h-[30px] flex rounded-md p-2"></div>
                                    </div>
                                </div>
                            </div>
                            <div className="relative mt-4">
                                <div className="bg-slatet w-full rounded aspect-[9/16]"></div>
                            </div>
                            <div className="flex items-center justify-end md:justify-between md:gap-3 my-3 md:px-[10px] group-hover:py-2 duration-1000 ease-in-out">
                                <div className="h-10 bg-slatet rounded-3xl w-full md:w-48 hidden md:inline-block"></div>
                                <div className="text-end">
                                    <div className="bg-slatet h-2 w-full rounded"></div>
                                    <div className="flex items-center mt-2">
                                        <div className="bg-slatet h-2 w-16 rounded flex-1"></div>
                                        <div className="bg-slatet h-2 w-16 rounded ml-3 flex-1"></div>
                                    </div>
                                </div>
                            </div>

                            <Link to="">
                                <div className="h-10 bg-slatet rounded w-full inline-flex"></div>
                            </Link>
                        </div>
                    </div>
                </div>

            </div>
        </>
    );
};

export default SkeletonLoaderEvent;