import React, {useEffect}from "react";
import { Routes, Route, Navigate } from "react-router-dom";
// Import Routes all
import { authProtectedRoutes, publicRoutes, publicRoutesWithoutHeader, publicRoutesWithoutHeaderFooter } from "./routes";

// Import all middleware
import Authmiddleware from "./routes/route";

// layouts Format
import Layout from "./components/Layout/";
import LayoutWithoutHeader from "./components/LayoutWithoutHeader/";
import LayoutWithoutHeaderFooter from "./components/LayoutWithoutHeaderFooter/";
import "./assets/css/App.css";
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import TagManager from 'react-gtm-module'

//get domain name from url
const domain = window.location.hostname;

const App = () => {
  console.log(domain);
    // Initialize google tag manager if domain is www.mepass.in
    if(domain === "www.mepass.in" || domain === "mepass.in"){
      const tagManagerArgs={
        gtmId: 'GTM-WWKL7MMC'
      };
      TagManager.initialize(tagManagerArgs);
    }
  return (
    <React.Fragment>
      <ToastContainer />
      <Routes>

        {publicRoutesWithoutHeader.map((route, idx) => (
          <Route
            path={route.path}
            element={
              <LayoutWithoutHeader>
                {route.component}
              </LayoutWithoutHeader>
            }
            key={idx}
            exact={true}
          />
        ))}
        {publicRoutes.map((route, idx) => (
          <Route
            path={route.path}
            element={
              <Layout>
                {route.component}
              </Layout>
            }
            key={idx}
            exact={true}
          />
        ))}
        {publicRoutesWithoutHeaderFooter.map((route, idx) => (
          <Route
            path={route.path}
            element={
              <LayoutWithoutHeaderFooter>
                {route.component}
              </LayoutWithoutHeaderFooter>
            }
            key={idx}
            exact={true}
          />
        ))}
        {authProtectedRoutes.map((route, idx) => (
          <Route
            path={route.path}
            element={
              <Authmiddleware>
                <Layout>{route.component}</Layout>
              </Authmiddleware>}
            key={idx}
            exact={true}
          />
        ))}
         <Route path="/index.html" element={<Navigate replace to="/" state="301"/>} />
         <Route path="/home" element={<Navigate replace to="/" state="301"/>} />
      </Routes>
    </React.Fragment>
  );
};

App.propTypes = {
};

export default App;