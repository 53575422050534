import React from "react";
import { Link } from "react-router-dom";

const SkeletonLoaderEventDetails = () => {
    return (
        <>
            <div className="hidden md:block">
                <div className="w-full h-[360px] bg-[#8e8e8e] pt-28 bg-right md:pt-[135px] pb-20">
                </div>
            </div>
            <div className="md:hidden block">
                <div className="w-full h-[360px] bg-[#8e8e8e] pt-28 bg-right md:pt-[135px] pb-20">
                </div>
            </div>
            <section className="bg-[#212121] pt-24 pb-24">
                <div className="container">
                    <div className="hidden md:block">
                        <div className="grid grid-cols-1 md:grid-cols-3 gap-0 md:gap-4 -mt-60">
                            <div className="bg-slatet rounded aspect-[9/16]"></div>
                            <div className="col-span-2 mt-10 md:mt-0 animate-pulse">
                                <div className="flex justify-end items-center gap-4 mb-10 md:mb-[105px] relative">
                                    <div className="h-2 bg-slatet rounded w-24"></div>
                                    <div className="h-10 bg-slatet rounded w-48 mt-3 block"></div>
                                </div>
                                <div className="flex items-center justify-between gap-5">
                                    <div className="h-2 bg-slatet rounded w-full flex-1"></div>
                                    <div className="bg-slatet py-2 px-5 rounded-full flex-none"></div>
                                </div>
                                <div className="flex items-start xl:items-center justify-between mt-5">
                                    <div className="flex flex-wrap items-center gap-3 mb-3 md:mb-0">
                                        <div className="bg-slatet py-5 px-16 rounded-full "></div>
                                        <div className="bg-slatet py-5 px-16 rounded-full "></div>
                                    </div>
                                    <div className="bg-slatet py-6 px-24 rounded flex-none"></div>
                                </div>
                                <div className="bg-[#000000] rounded-lg p-4 mt-5">
                                    <div className="flex justify-between gap-5">
                                        <div className="h-2 bg-slatet rounded w-full flex-1"></div>
                                        <div className="text-end flex-none">
                                            <div className="h-2 bg-slatet rounded w-full "></div>
                                            <div className="flex items-center mt-2">
                                                <div className="h-2 bg-slatet rounded w-20 mr-3"></div>
                                                <div className="h-2 bg-slatet rounded w-20 "></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex items-center gap-5 mt-4">
                                        <div className="h-2 bg-slatet rounded w-24"></div>
                                        <div className="h-2 bg-slatet rounded w-24"></div>
                                    </div>
                                    <div className="block lg:flex items-center justify-between mt-5">
                                        <div className="bg-slatet py-5 px-24 rounded-full "></div>
                                        <div className="h-10 bg-slatet rounded w-48 mt-3 block"></div>
                                    </div>
                                </div>
                                <div className="flex items-center gap-4 mt-5">
                                    <div className="bg-slatet w-[48px] h-[48px] flex rounded-full"></div>
                                    <div className="bg-slatet w-[48px] h-[48px] flex rounded-full"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="md:hidden block">
                        <div className="-mt-[320px]">
                            <div className="flex justify-center w-[70%] m-auto">
                                <div className="text-center">
                                    <div className="h-3 bg-slatet rounded w-full animate-pulse"></div>
                                    <div className="mt-6">
                                        <div className="text-center">
                                            <div className="relative">
                                                <div className="bg-slatet rounded aspect-[9/16]"></div>
                                                <div className="absolute top-0 -right-[46px]">
                                                    <div className="bg-slatet w-[35px] h-[35px] flex items-center justify-center rounded-full mt-2"></div>
                                                </div>
                                                <div className="absolute top-10 -right-[46px]">
                                                    <div className="bg-slatet w-[35px] h-[35px] flex items-center justify-center rounded-full mt-2"></div>
                                                </div>
                                            </div>

                                            <div className="flex animate-pulse justify-end items-center gap-4 mt-6">
                                                <div className="h-3 bg-slatet rounded w-16"></div>
                                                <div className="bg-slatet rounded w-[140px] h-4"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="flex animate-pulse items-center justify-center flex-nowrap gap-4 mt-6">
                                <div className="h-10 bg-slatet rounded w-48 block"></div>
                                <div className="h-10 bg-slatet rounded w-48 block"></div>
                            </div>

                            <div className="flex animate-pulse items-center justify-between mt-3">
                                <div className="">
                                    <div className="h-3 bg-slatet rounded w-24"></div>
                                    <div className="flex items-center gap-3 mt-2">
                                        <div className="h-3 bg-slatet rounded w-16"></div>
                                        <div className="h-3 bg-slatet rounded w-16"></div>
                                    </div>
                                </div>
                                <div className="h-10 bg-slatet rounded w-40 block mt-3 md:mt-0 animate-pulse"></div>
                            </div>

                            <div className="flex items-center overflow-auto gap-2 mt-6 animate-pulse">
                                <div className="bg-slatet py-5 px-16 rounded-full "></div>
                                <div className="bg-slatet py-5 px-16 rounded-full "></div>
                            </div>

                            <div className="bg-[#000000] rounded-lg p-4 mt-6 animate-pulse">
                            <div className="h-3 bg-slatet rounded w-full"></div>
                                <div className="flex items-center justify-between gap-5 mt-3">
                                    <div className="flex items-center gap-2">
                                    <div className="h-3 bg-slatet rounded w-24"></div>
                                    <div className="h-3 bg-slatet rounded w-14"></div>
                                    </div>
                                    <div className="bg-slatet rounded-full py-2 px-6 flex-none"></div>
                                </div>
                                <div className="bg-slatet py-5 px-16 rounded-full w-48 mt-4"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default SkeletonLoaderEventDetails;
