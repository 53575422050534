import React, { useEffect } from 'react';
import banner3 from '../../assets/images/terms-condition-banner.png';
import { Link } from 'react-router-dom';
import SeoDetails from '../../seo/SeoDetails';
function RefundCancellationPolicy() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <SeoDetails title="mepass | Refund and Cancellation Policy"
                description="mepass | Refund and Cancellation Policy"   
            />
            <div className="w-full h-full md:h-[400px] bg-cover pt-28 bg-right md:pt-[135px] pb-4 md:pb-20" style={{ backgroundImage: `url(${banner3})` }}>
                <div className="container">
                    <h2 className='text-white text-[38px] md:text-[64px] font-body font-semibold h-[240px] flex items-end'>
                        Refund and Cancellation Policy
                    </h2>
                </div>
            </div>
            <div className='bg-neutral py-14'>
                <div className="container">
                    <h3 className='text-[30px] font-body font-normal text-[#FFD254] mb-2'>User Refund and Cancellation Policy</h3>
                    <h4 className='text-xl font-body font-normal text-white mb-2'>Effective Date: [Insert Date]</h4>
                    <p className='text-[#DCDCDC] text-base font-body font-normal mb-2'>
                        Memorialeye Event Management Private Limited ("we," "us," "our") operates under the brand name Mepass. This Refund and Cancellation Policy applies to all users ("you," "your") who book tickets or register for events or activities through our platform, Mepass, which is accessible at mepass.in.
                    </p>
                    <h4 className='text-xl font-body font-normal text-white mb-2'>1. Service Overview</h4>
                    <p className='text-[#DCDCDC] text-base font-body font-normal mb-2'>
                        Mepass provides services to our B2B clients, including company account registration, event hosting and management, and various event-related products and services available for purchase and use through our B2B customer portal or account. There are no different categories or tiers of services for merchants.
                    </p>
                    <h4 className='text-xl font-body font-normal text-white mb-2'>2. Booking Process</h4>
                    <p className='text-[#DCDCDC] text-base font-body font-normal mb-2'>
                        Registration and Booking Procedure:Merchants can visit our merchant portal or use the merchant application to register as an individual or company and join Mepass as a merchant.
                        <br />
                        Deposit Requirements: No deposit is required at the time of booking.
                    </p>
                    <h4 className='text-xl font-body font-normal text-white mb-2'>3. Refund Conditions</h4>
                    <p className='text-[#DCDCDC] text-base font-body font-normal mb-2'>
                        Non-Refundable Services: Mepass does not initiate refunds for any services offered to merchants. Once a merchant purchases or activates a service for their respective purposes, it is non-refundable under any circumstances.
                        <br />
                        Time Frames for Refund Requests: No refunds are applicable.
                    </p>
                    <h4 className='text-xl font-body font-normal text-white mb-2'>4. Cancellation Policy</h4>
                    <p className='text-[#DCDCDC] text-base font-body font-normal mb-2'>
                        Merchant-Initiated Cancellations: Once a merchant registers or purchases any services offered, they are not eligible to cancel those services or request a refund.
                        <br />
                       Penalty or Fees for Cancellations: Cancellations are not allowed, hence no penalties or fees apply.
                    </p>
                    <h4 className='text-xl font-body font-normal text-white mb-2'>5. Non-Refundable Conditions</h4>
                    <p className='text-[#DCDCDC] text-base font-body font-normal mb-2'>
                        Non-Refundable Payments: All payments for services purchased by merchants are non-refundable, regardless of the type of service or event.
                    </p>
                    <h4 className='text-xl font-body font-normal text-white mb-2'>6. Force Majeure</h4>
                    <p className='text-[#DCDCDC] text-base font-body font-normal mb-2'>
                        Unforeseen Circumstances: In the event of unforeseen circumstances such as natural disasters or pandemics, the purchased services will remain as a balance in your portal and can be redeemed for future service needs.
                    </p>
                    <h4 className='text-xl font-body font-normal text-white mb-2'>7. Refund Processing</h4>
                    <p className='text-[#DCDCDC] text-base font-body font-normal mb-2'>
                        Refund Time Frame: No refunds are processed.
                        <br />
                        Refund Method: No refunds are issued.
                    </p>
                    <h4 className='text-xl font-body font-normal text-white mb-2'>8. Communication</h4>
                    <p className='text-[#DCDCDC] text-base font-body font-normal mb-2'>
                        Refund and Cancellation Requests: As no refunds or cancellations are accepted, merchants should not request refunds or cancellations.
                        <br />
                        Customer Service Team: As refunds are not provided, there is no dedicated customer service team for handling such requests.
                    </p>
                    <h4 className='text-xl font-body font-normal text-white mb-2'>9. Contact Information</h4>
                    <p className='text-[#DCDCDC] text-base font-body font-normal mb-2'>
                        For any queries related to this Merchant Refund and Cancellation Policy, please contact our support team at: Memorialeye Event Management Private Limited (Mepass)
                    </p>
                    <p className='text-[#DCDCDC] text-base font-body font-normal mt-3'>
                        Email: <Link to="" className='underline'>onboarding@mepass.in</Link>
                        <br />
                        Phone: 9099060633
                        <br />
                        Address: A/209 Titanium Business Park, Near Makarba Railway Underbridge, Behind Divya Bhaskar Ahmedabad 380051
                    </p>
                    <p className='text-[#DCDCDC] text-base font-body font-normal mt-2'>
                        This policy ensures clarity and fairness for merchants using Mepass services, maintaining trust and transparency in our operations. Memorialeye Event Management Private Limited reserves the right to amend this policy at any time without prior notice. Any changes will be posted on our website and will be effective immediately upon posting.
                    </p>
                    <h3 className='text-[30px] font-body font-normal text-[#FFD254] mb-2 mt-5'>Merchant Refund and Cancellation Policy </h3>
                    <h4 className='text-xl font-body font-normal text-white mb-2'>Effective Date: [Insert Date]</h4>
                    <p className='text-[#DCDCDC] text-base font-body font-normal mb-2'>
                        Memorialeye Event Management Private Limited ("we," "us," "our") operates under the brand name Mepass. This Merchant Refund and Cancellation Policy applies to all merchants ("you," "your") who register and use our services through our platform, Mepass, which is accessible via our merchant portal or merchant application.
                    </p>
                    <h4 className='text-xl font-body font-normal text-white mb-2'>1. Service Overview</h4>
                    <p className='text-[#DCDCDC] text-base font-body font-normal mb-2'>
                        Mepass is an event ticketing booking platform where users can book tickets or register for a variety of live entertainment events and activities. Our platform does not offer different tiers of services for users.
                    </p>
                    <h4 className='text-xl font-body font-normal text-white mb-2'>2. Booking Process</h4>
                    <p className='text-[#DCDCDC] text-base font-body font-normal mb-2'>
                        Booking Procedure: Users must log in to mepass.in, select an event, review all event details and terms and conditions, and then click the "Book" button. Users can select their preferred pass type and quantity and will be redirected to the payment page to complete the booking using the available payment options.
                        Free Events/Registrations: For free events or events requiring registration, users must follow the specified registration process
                        <br />
                        Deposit Requirements: Generally, no deposit is required at the time of booking unless specified in the event's terms and conditions. Any such requirements will be clearly mentioned under the event's terms and conditions and refund policy.
                    </p>
                    <h4 className='text-xl font-body font-normal text-white mb-2'>3. Refund Conditions</h4>
                    <p className='text-[#DCDCDC] text-base font-body font-normal mb-2'>
                        Event Cancellation: If an event is cancelled and you purchased the pass through mepass.in using our payment gateway, Mepass will initiate a refund, subject to certain conditions.
                        Third-Party Purchases: If you have a ticket purchased through a third party (e.g., event organiser team members), Mepass is not liable for refunds.
                        Individual Cancellation Requests:** For cancellations initiated by users due to personal reasons, Mepass is not liable for refunds. Users must contact the event organiser directly for such requests.
                        Refund Requests:** After booking tickets for any event, users cannot request refunds or cancellations through Mepass. Refund requests must be directed to the event organiser and their team.
                        Event Postponement:** If an event is cancelled or delayed, Mepass will initiate a refund process for tickets purchased through mepass.in.

                    </p>
                    <h4 className='text-xl font-body font-normal text-white mb-2'>4. Cancellation Policy</h4>
                    <p className='text-[#DCDCDC] text-base font-body font-normal mb-2'>
                    User-Initiated Cancellations:** Once a ticket is booked, users cannot cancel their booking through Mepass. Cancellation requests must be directed to the event organizer or management team.
                    Convenience and Transaction Charges: In the event of an event cancellation or delay, the decision on whether the convenience charge and transaction charges are covered by the organizer or deducted from the user's refund will depend on the organizer. If the charges are to be borne by the user, the refund amount will be processed after deducting these charges.
                    </p>
                    <h4 className='text-xl font-body font-normal text-white mb-2'>5. Non-Refundable Conditions</h4>
                    <p className='text-[#DCDCDC] text-base font-body font-normal mb-2'>
                    Payments are non-refundable if the cancellation request is due to individual reasons and not related to event cancellation or delay.
                    </p>
                    <h4 className='text-xl font-body font-normal text-white mb-2'>6. Force Majeure</h4>
                    <p className='text-[#DCDCDC] text-base font-body font-normal mb-2'>
                    In cases of unforeseen circumstances such as natural disasters or pandemics that lead to event cancellation, Mepass will process refunds for tickets purchased through our platform.
                    </p>
                    <h4 className='text-xl font-body font-normal text-white mb-2'>7. Refund Processing</h4>
                    <p className='text-[#DCDCDC] text-base font-body font-normal mb-2'>
                    Processing Time: Refunds will be processed within 15 days after receiving confirmation from the management and organizer team regarding the event cancellation.
                        <br />
                        Refund Method: Refunds will be credited back to the original payment source.
                    </p>
                    <h4 className='text-xl font-body font-normal text-white mb-2'>8. Communication</h4>
                    <p className='text-[#DCDCDC] text-base font-body font-normal mb-2'>
                    Refund Requests: For event cancellations, users can contact our support and helpline to request a refund. Individual reasons for refund or cancellation requests will not be accepted.
                        <br />
                        Customer Service: Mepass has a dedicated customer service team to handle refund and cancellation requests related to event cancellations.
                    </p>
                    <h4 className='text-xl font-body font-normal text-white mb-2'>9. Contact Information</h4>
                    <p className='text-[#DCDCDC] text-base font-body font-normal mb-2'>
                    For any queries related to this Refund and Cancellation Policy, please contact our customer service team at: Memorialeye Event Management Private Limited (Mepass)
                    </p>
                    <p className='text-[#DCDCDC] text-base font-body font-normal mt-3'>
                        Email: <Link to="" className='underline'>support@mepass.in</Link>
                        <br />
                        Phone: 9925060619
                    </p>
                    <p className='text-[#DCDCDC] text-base font-body font-normal mt-2'>
                    This policy is designed to ensure clarity and fairness for users booking through Mepass, maintaining trust and transparency in our services. Memorialeye Event Management Private Limited reserves the right to amend this policy at any time without prior notice. Any changes will be posted on our website and will be effective immediately upon posting.
                    </p>
                </div>
            </div>
        </>
    );
}

export default RefundCancellationPolicy;
