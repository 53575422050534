import React, { useState, useEffect, useCallback } from "react";
import banner2 from "../../assets/images/organiser-banner.png";
import testimonail from "../../assets/images/small/testimonail-logo.png";
import img from "../../assets/images/artiest-img.png";
import img1 from "../../assets/images/sartaj.png";
import img2 from "../../assets/images/event-img.png";
import img3 from "../../assets/images/featured-img2.png";
import img4 from "../../assets/images/vivek.png";
import img5 from "../../assets/images/bashi.png";
import img6 from "../../assets/images/pannu.png";
import img7 from "../../assets/images/para.png";
import follow1 from "../../assets/images/follow1.png";
import follow2 from "../../assets/images/follow2.png";
import follow3 from "../../assets/images/follow3.png";
import follow4 from "../../assets/images/follow4.png";
import icon from "../../assets/images/small/star-yellow.png";
import icon2 from "../../assets/images/small/megaphone.png";
import icon3 from "../../assets/images/small/percentage-red.png";
import icon4 from "../../assets/images/small/megaphone-dark.png";
import icon5 from "../../assets/images/small/percentage-yellow.png";
import banner from "../../assets/images/mobile/artist-banner.png"
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import api from "../../constants/api";
import { isEmpty } from "lodash";
import { getData, clearData, addUpdateNewData } from "../../store/AppMaster/actions";
import { Link } from 'react-router-dom';
import EventView from "../../components/Event/View";
import withRouter from "../../components/Common/withRouter";
import SeoDetails from "../../seo/SeoDetails";
import SkeletonLoaderArtiest from "../../components/SkeletonLoader/Artiest/Index"

function Artiest(props) {
  const [activeButton, setActiveButton] = useState(1);
  const [eventDetail, setEventDetail] = useState(null);
  const [isEventData, setIsEventData] = useState([]);
  const [isApiCall, setIsApiCall] = useState(0);
  const [artistDetails, setArtistDetails] = useState(false);
  const [artistView, setArtistView] = useState({});
  const [isUpcomingEventData, setIsUpcomingEventData] = useState(false);
  const [upcomingEventDetail, setUpcomingEventDetail] = useState('');
  const [isAddData, setIsAddData] = useState(true);
  const [favoriteList, getFavoriteList] = useState(false);

  const params = useParams();
  const dispatch = useDispatch();

  const { data } = useSelector(state => ({
    data: state.AppMaster.data,
  }));
  const { loading } = useSelector(state => ({
    loading: state.AppMaster.loading,
  }));
  const ratingData = artistView.ratingUser || {};

  const handleButtonClick = (buttonId) => {
    setActiveButton(buttonId);
    if (buttonId === 1) {
      upComingEventDataGet(); // Fetch upcoming events
    } else {
      eventDataGet(); // Fetch past events
    }
  };

  const eventDataGet = useCallback(() => {
    setIsEventData(true);

    let requestData = {
      artist_slug: params.artiest_slug,
      events: 'Past',
    };
    dispatch(clearData());
    dispatch(getData(requestData, "", api.EventList));
  });

  const upComingEventDataGet = useCallback(() => {
    setIsUpcomingEventData(true);
    let requestData = {
      artist_slug: params.artiest_slug,
      events: 'Upcoming',
    };
    dispatch(clearData());
    dispatch(getData(requestData, "", api.EventList));
  });

  const addToFavorite = () => {
    if (localStorage.getItem("publicMePassUser")) {
      setIsAddData(true);
      const newData = {
        favorite_id: artistView.id,
        type: 'Artist',
      }
      dispatch(addUpdateNewData(newData, props.router.navigate, api.AddFavorites));
    } else {
      props.router.navigate('/login', { state: { is_login_page: 'Yes' } });
    }

  };

  if (!isEmpty(data) && !isEmpty(data.favoritesAdd) && isAddData) {
    setIsAddData(false);
    artistView.is_favorite = 'Yes';
  }

  // Remove favorite
  const [isRemoveData, setIsRemoveData] = useState(false);
  const removeToFavorite = (favorite_id) => {
    setIsRemoveData(true);
    const newData = {
      favorite_id: favorite_id,
      type: 'Artist',
    }
    dispatch(addUpdateNewData(newData, props.router.navigate, api.RemoveFavorites));
  }
  if (!isEmpty(data) && !isEmpty(data.favoritesDelete) && isRemoveData) {
    setIsRemoveData(false);
    artistView.is_favorite = 'No';
  }

  useEffect(() => {
    if (isApiCall === 0) {
      getSponsorDetails();
      setIsApiCall(1);
    }
  }, [isApiCall]);

  const getSponsorDetails = () => {
    setArtistDetails(true);
    const userDetail = JSON.parse(
      localStorage.getItem("publicMePassUserDetails"),
    )
    const requestData = {
      artist_slug: params.artiest_slug,
      user_id: userDetail && userDetail.id ? userDetail.id : '',
    };
    dispatch(clearData());
    dispatch(getData(requestData, props.router.navigate, api.ArtistView));
  };

  useEffect(() => {
    if (!isEmpty(data) && !isEmpty(data.artistView) && artistDetails) {
      upComingEventDataGet();
      setArtistView(data.artistView);
      setArtistDetails(false);
    }
    if (!isEmpty(data) && Array.isArray(data.eventList)) {
      if (activeButton === 1) {
        setEventDetail(data.eventList); // Update past events
      } else {
        setUpcomingEventDetail(data.eventList); // Update upcoming events
      }
    }
    if (!isEmpty(data) && !isEmpty(data.profileView) && profileFlag) {
      setProfileData(data.profileView);
    }
  }, [data, artistDetails]);

  const totalRatings = Object.values(ratingData).reduce((acc, count) => acc + count, 0);

  const getPercentage = (count) => (totalRatings > 0 ? (count / totalRatings) * 100 : 0);

  const instagramIntegration = process.env.REACT_APP_ARTIST_VIEW_INSTAGRAM_INTIGRATION === 'true';
  const testimonialEnabled = process.env.REACT_APP_ARTIST_VIEW_TESTIMONIAL === 'true';
  console.log(instagramIntegration, testimonialEnabled);

  return (
    <>
      <SeoDetails title={`${artistView.name + ' | mepass.in'}`}
        ogImage={artistView.image}
      />
      {loading ? <SkeletonLoaderArtiest /> :
        <>
          <div className="hidden md:block">
            <div
              className="w-full h-[360px] bg-cover pt-28 bg-right md:pt-[135px] pb-20 "
              style={{ backgroundImage: `url(${banner2})` }}
            >
            </div>
          </div>
          <div className="md:hidden block">
            <div
              className="w-full bg-cover pt-28 bg-right md:pt-[135px] bg-gradient-to-b from-black to-[#404040]"
            >
              <img src={banner} alt="img" className="inline-block w-full" />
            </div>
          </div>
          <section className="bg-neutral pt-4 pb-0 md:pb-24">
            <div className="container">
              {/* -------------------------------section-start------------------------- */}
              <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 items-end gap-4 md:gap-8 -mt-[100px]">
                <div>
                  <img
                    src={artistView.image}
                    alt=""
                    className="rounded-[5px] md:border border-secondary"
                  />
                </div>
                <div className="col-span-1 md:col-span-2 lg:col-span-3">
                  <div className="block md:hidden">
                    <h2 className="text-xl font-body font-medium text-white">
                      {artistView.artist_type_name}
                    </h2>
                    {artistView.rating > 0 && totalRatings > 0 && (
                      <div className="bg-primary flex items-center justify-center w-[72px] h-[32px] rounded-full mt-2">
                        <img src={icon} alt="" className="inline-block size-4 mr-1" />
                        <span className="text-white text-base font-body font-bold">{artistView.rating === "" ? 0 : artistView.rating}</span>
                      </div>
                    )}
                  </div>
                  <div className="hidden md:block">
                    <div className="flex justify-between mb-7">
                      <div className="flex-1">
                        <h2 className="text-4xl md:text-3xl font-body font-medium text-white">
                          {artistView.name}
                        </h2>
                      </div>
                      <div className="flex-none">
                        {artistView.rating > 0 && totalRatings > 0 && (
                          <div className="bg-primary flex items-center justify-center w-[72px] h-[32px] rounded-full mt-3 md:mt-0">
                            <img src={icon} alt="" className="inline-block mr-1" />
                            <span className="text-white text-xl font-body font-bold">
                              {artistView.rating === "" ? 0 : artistView.rating}
                            </span>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="block md:flex items-center gap-4 md:gap-6">
                      {artistView.fanbase && (
                        <div className="py-[10px] px-[30px] rounded-full md:text-base 2xl:text-xl font-body font-normal text-white border border-white">
                          <i className="fas fa-user-friends text-lg align-middle mr-1"></i>{" "}
                          Fan base : <span className="font-semibold">{artistView.fanbase >= 1 ? artistView.fanbase : 0} </span>
                        </div>
                      )}
                      {artistView.total_events && (
                        <div className="py-[10px] px-[30px] rounded-full md:text-base 2xl:text-xl font-body font-normal text-white border border-white">
                          <i className="fas fa-star text-lg align-middle mr-1"></i> Events
                          : <span className="font-semibold">{artistView.total_events >= 1 ? artistView.total_events : 0} </span>
                        </div>
                      )}
                    </div>
                    <div className="flex items-center justify-between gap-6 mt-5">

                      <ul className="flex items-center gap-2 lg:gap-4">
                        {artistView.facebook_url && (
                          <li>
                            <a href={artistView.facebook_url} target="_blank" rel="noopener noreferrer">
                              <div className="bg-white rounded-sm text-primary w-[40px] h-[40px] lg:w-[48px] lg:h-[48px] flex items-center justify-center">
                                <i className="fab fa-facebook-f text-xl lg:text-2xl"></i>
                              </div>
                            </a>
                          </li>
                        )}

                        {artistView.twitter_url && (
                          <li>
                            <a href={artistView.twitter_url} target="_blank" rel="noopener noreferrer">
                              <div className="bg-white rounded-sm text-primary w-[40px] h-[40px] lg:w-[48px] lg:h-[48px] flex items-center justify-center">
                                <i className="fab fa-twitter text-2xl"></i>
                              </div>
                            </a>
                          </li>
                        )}

                        {artistView.youtube_url && (
                          <li>
                            <a href={artistView.youtube_url} target="_blank" rel="noopener noreferrer">
                              <div className="bg-white rounded-sm text-primary w-[40px] h-[40px] lg:w-[48px] lg:h-[48px] flex items-center justify-center">
                                <i className="fab fa-youtube text-2xl"></i>
                              </div>
                            </a>
                          </li>
                        )}

                        {artistView.instagram_url && (
                          <li>
                            <a href={artistView.instagram_url} target="_blank" rel="noopener noreferrer">
                              <div className="bg-white rounded-sm text-primary w-[40px] h-[40px] lg:w-[48px] lg:h-[48px] flex items-center justify-center">
                                <i className="fab fa-instagram-square text-2xl"></i>
                              </div>
                            </a>
                          </li>
                        )}
                      </ul>


                      {localStorage.getItem("publicMePassUser") ? (
                        artistView.is_favorite === 'Yes' ? (
                          <button onClick={() => removeToFavorite(artistView.id)} className="border border-secondary tracking-[.25em] py-[8px] px-[16px] rounded-md text-sm text-[#FFD254] font-normal font-body w-full md:w-[260px] lg:w-[301px] inline uppercase">
                            <i className="fal fa-minus ml-2"></i>Remove Favorite
                          </button>
                        ) : (
                          <button onClick={() => addToFavorite(artistView.id)} className="border border-secondary tracking-[.25em] py-[8px] px-[16px] rounded-md text-sm text-[#FFD254] font-normal font-body w-full md:w-[260px] lg:w-[301px] inline uppercase">
                            <i className="fas fa-plus mr-3"></i>Add to favorites
                          </button>
                        )
                      ) : ''}
                    </div>
                  </div>
                </div>
              </div>

              {/* -------------------------------section-end------------------------- */}

              {/* -------------------------------only-show-mobile-view-start------------------------- */}

              <div className="md:hidden block mt-5">
                <div className="flex items-start justify-between gap-3 mt-3 bg-black rounded-lg p-3">
                  <div>
                    {artistView.fanbase && (
                      <div className="py-1 px-3 rounded-full text-md font-body font-normal text-white border border-white">
                        <i className="fas fa-user-friends text-sm align-middle mr-1"></i>{" "}
                        Fan base : <span className="font-semibold">{artistView.fanbase} </span>
                      </div>
                    )}
                    {artistView.total_events && (
                      <div className="py-1 px-3 rounded-full text-md font-body font-normal text-white border border-white mt-3">
                        <i className="fas fa-star text-sm align-middle mr-1"></i> Events :{" "}
                        <span className="font-semibold">{artistView.total_events} </span>
                      </div>
                    )}
                  </div>
                  {(artistView && (artistView.facebook_url || artistView.instagram_url || artistView.youtube_url)) && (

                    <div>
                      <h4 className="text-lg text-white font-body font-medium">Follow</h4>
                      <ul className="flex items-center gap-2 mt-2">
                        {artistView.facebook_url && (
                          <li>
                            <a href={artistView.facebook_url} target="_blank" rel="noopener noreferrer">
                              <div className="bg-white rounded-sm text-primary w-[25px] h-[25px] flex items-center justify-center">
                                <i className="fab fa-facebook-f text-md"></i>
                              </div>
                            </a>
                          </li>
                        )}

                        {artistView.twitter_url && (
                          <li>
                            <a href={artistView.twitter_url} target="_blank" rel="noopener noreferrer">
                              <div className="bg-white rounded-sm text-primary w-[25px] h-[25px] flex items-center justify-center">
                                <i className="fab fa-twitter text-md"></i>
                              </div>
                            </a>
                          </li>
                        )}


                        {artistView.youtube_url && (
                          <li>
                            <a href={artistView.youtube_url} target="_blank" rel="noopener noreferrer">
                              <div className="bg-white rounded-sm text-primary w-[25px] h-[25px] flex items-center justify-center">
                                <i className="fab fa-youtube text-md"></i>
                              </div>
                            </a>
                          </li>
                        )}

                        {artistView.instagram_url && (
                          <li>
                            <a href={artistView.instagram_url} target="_blank" rel="noopener noreferrer">
                              <div className="bg-white rounded-sm text-primary w-[25px] h-[25px] flex items-center justify-center">
                                <i className="fab fa-instagram-square text-md"></i>
                              </div>
                            </a>
                          </li>
                        )}
                      </ul>
                    </div>
                  )}
                </div>
              </div>

              {/* -------------------------------only-show-mobile-view-end------------------------- */}

              {/* -------------------------------About-start------------------------- */}
              {artistView.about_artist && (
                <div className="pt-5 md:pt-12">
                  <h2 className="text-2xl md:text-[40px] font-body font-light tracking-[0.3rem] text-white uppercase">
                    About ArtIst
                  </h2>
                  <p className="text-[#DCDCDC] text-base font-body font-normal mt-4">
                    {artistView.about_artist}
                  </p>
                </div>
              )}
              {/* -------------------------------About-end------------------------- */}

              {/* -------------------------------review-start------------------------- */}
              {(artistView.rating && Object.values(ratingData).some(value => value > 0)) && (

                <div className="pt-14 hidden md:block">
                  <div className="flex items-center justify-start md:justify-center border-0 md:border border-secondary w-full md:w-[79.5%] p-0 md:p-10 rounded-[20px] m-auto gap-6">
                    <span className="text-white font-body font-normal text-5xl lg:text-[80px]">
                      {artistView.rating === "" ? 0 : artistView.rating}
                    </span>

                    <ol className="list-decimal text-white ml-6 lg:ml-12 w-full">
                      {[1, 2, 3, 4, 5].map((star) => (
                        <li key={star} className="mt-2">
                          <div className="flex items-center gap-2">
                            <i className="fas fa-star text-[#FCB426] text-sm"></i>
                            <div className="w-full bg-gray-200 rounded-full h-[4px] lg:h-2">
                              <div
                                className="bg-primary h-[4px] lg:h-2 rounded-full"
                                style={{ width: `${getPercentage(ratingData[star] || 0)}%` }}
                              >
                                {/* Optional: Display the number of ratings */}
                                <span className="sr-only">{ratingData[star]} ratings</span>
                              </div>
                            </div>
                          </div>
                        </li>
                      ))}
                    </ol>
                  </div>
                </div>
              )}
              {/* -------------------------------review-end------------------------- */}

              {/* ------------------------------section-end------------------------- */}

              {
                instagramIntegration && (
                  <div className="pt-10 md:pt-24">
                    <a href={artistView.youtube_url} target="_blank" rel="noopener noreferrer">
                      <button className="bg-primary border border-primary text-white text-sm md:text-base font-normal tracking-[.25em] py-1 md:py-2 px-2 md:px-8 rounded font-body hover:bg-white hover:text-primary duration-1000 ease-in-out uppercase">
                        <i className="fab fa-instagram mr-1 md:mr-3"></i>Follow Instgram
                      </button>
                    </a>
                    <div className="grid grid-cols-4 gap-4 md:gap-8 mt-8">
                      <div>
                        <img src={follow1} alt="" className="w-full" />
                      </div>
                      <div>
                        <img src={follow2} alt="" className="w-full" />
                      </div>
                      <div>
                        <img src={follow3} alt="" className="w-full" />
                      </div>
                      <div>
                        <img src={follow4} alt="" className="w-full" />
                      </div>
                    </div>
                  </div>)
              }
              {/* -------------------------------section-end------------------------- */}

              {/* -----------------------------------section-start---------------------- */}


              <div className='pt-5 md:pt-24'>
                <div className="bg-[#1B1B1B] p-2 rounded-lg mb-10">
                  <div className="flex items-center justify-between">
                    <button
                      className={`${activeButton === 1 ? 'active-button bg-secondary rounded-md !text-black font-semibold' : ''} py-1 text-white font-body font-normal text-[18px] w-1/2`}
                      onClick={() => handleButtonClick(1)}
                    >
                      Upcoming Events
                    </button>
                    <button
                      className={`${activeButton === 2 ? 'active-button !bg-secondary rounded-md !text-black font-semibold' : ''} bg-transparent py-1 text-white font-body font-normal text-[18px] w-1/2`}
                      onClick={() => handleButtonClick(2)}
                    >
                      Past Events
                    </button>
                  </div>
                </div>
                <div>
                  {activeButton === 1 ? (
                    <div className="grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
                      {eventDetail && eventDetail.length > 0 ? (
                        eventDetail.map((singleEvent, index) => ( // past event
                          <Link to={"/events/" + singleEvent.slug} key={index}>
                            <EventView singleEvent={singleEvent} />
                          </Link>
                        ))
                      ) : (
                        <p className="col-span-4 text-white text-center">No upcoming events</p>
                      )}
                    </div>
                  ) : (
                    <div className="grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
                      {upcomingEventDetail && upcomingEventDetail.length > 0 ? (
                        upcomingEventDetail.map((singleEvent, index) => ( // upcoming event
                          <Link to={"/events/" + singleEvent.slug} key={index}>
                            <EventView singleEvent={singleEvent} />
                          </Link>
                        ))
                      ) : (
                        <p className="col-span-4 text-white text-center">No past events</p>
                      )}
                    </div>
                  )}
                </div>

              </div>


              {/* ----------------------------------section-end----------------------- */}

              {/* -------------------------------Testimonail-Start------------------------- */}


              {testimonialEnabled && (
                <div className="pt-10 md:pt-24">
                  <h2 className="text-2xl md:text-[40px] font-body font-light tracking-[.150em] text-white uppercase text-center">
                    Testimonial
                  </h2>
                  <div className="hidden md:block">
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-8 mt-8">
                      {/* first-card */}
                      <div className="bg-black p-5 rounded-lg">
                        <div className="flex items-center justify-between">
                          <div>
                            <h3 className="text-white text-base font-body font-bold">
                              Jenny Wilson
                            </h3>
                            <div className="flex items-center gap-1 text-primary text-xs mt-2">
                              <i className="fas fa-star"></i>
                              <i className="fas fa-star"></i>
                              <i className="fas fa-star"></i>
                              <i className="fas fa-star"></i>
                              <i className="fas fa-star"></i>
                            </div>
                          </div>
                          <img src={testimonail} alt="" className="rounded-lg" />
                        </div>
                        <p className="text-[#DDDDDD] text-lg font-body font-normal mt-3">
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the industry's
                          standard dummy text ever since the 1500s
                        </p>
                      </div>
                      {/* secound-card */}
                      <div className="bg-black p-5 rounded-lg">
                        <div className="flex items-center justify-between">
                          <div>
                            <h3 className="text-white text-base font-body font-bold">
                              Jenny Wilson
                            </h3>
                            <div className="flex items-center gap-1 text-primary text-xs mt-2">
                              <i className="fas fa-star"></i>
                              <i className="fas fa-star"></i>
                              <i className="fas fa-star"></i>
                              <i className="fas fa-star"></i>
                              <i className="fas fa-star"></i>
                            </div>
                          </div>
                          <img src={testimonail} alt="" className="rounded-lg" />
                        </div>
                        <p className="text-[#DDDDDD] text-lg font-body font-normal mt-3">
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the industry's
                          standard dummy text ever since the 1500s
                        </p>
                      </div>
                      {/* third-card */}
                      <div className="bg-black p-5 rounded-lg">
                        <div className="flex items-center justify-between">
                          <div>
                            <h3 className="text-white text-base font-body font-bold">
                              Jenny Wilson
                            </h3>
                            <div className="flex items-center gap-1 text-primary text-xs mt-2">
                              <i className="fas fa-star"></i>
                              <i className="fas fa-star"></i>
                              <i className="fas fa-star"></i>
                              <i className="fas fa-star"></i>
                              <i className="fas fa-star"></i>
                            </div>
                          </div>
                          <img src={testimonail} alt="" className="rounded-lg" />
                        </div>
                        <p className="text-[#DDDDDD] text-lg font-body font-normal mt-3">
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the industry's
                          standard dummy text ever since the 1500s
                        </p>
                      </div>
                      {/* forth-card */}
                      <div className="bg-black p-5 rounded-lg">
                        <div className="flex items-center justify-between">
                          <div>
                            <h3 className="text-white text-base font-body font-bold">
                              Jenny Wilson
                            </h3>
                            <div className="flex items-center gap-1 text-primary text-xs mt-2">
                              <i className="fas fa-star"></i>
                              <i className="fas fa-star"></i>
                              <i className="fas fa-star"></i>
                              <i className="fas fa-star"></i>
                              <i className="fas fa-star"></i>
                            </div>
                          </div>
                          <img src={testimonail} alt="" className="rounded-lg" />
                        </div>
                        <p className="text-[#DDDDDD] text-lg font-body font-normal mt-3">
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the industry's
                          standard dummy text ever since the 1500s
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="md:hidden block">
                    <div className="grid grid-cols-2 gap-4 mt-8">
                      {/* first-card */}
                      <div className="bg-black p-2 rounded-lg">
                        <div className="flex items-center justify-between">
                          <div>
                            <h3 className="text-white text-sm font-body font-bold">
                              Jenny Wilson
                            </h3>
                            <div className="flex items-center gap-1 text-primary text-[12px] mt-1">
                              <i className="fas fa-star"></i>
                              <i className="fas fa-star"></i>
                              <i className="fas fa-star"></i>
                              <i className="fas fa-star"></i>
                              <i className="fas fa-star"></i>
                            </div>
                          </div>
                          <img src={testimonail} alt="" className="rounded-lg w-[40%]" />
                        </div>
                        <p className="text-[#DDDDDD] text-sm font-body font-normal mt-3">
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the industry's
                          standard dummy text ever since the 1500s
                        </p>
                      </div>
                      {/* secound-card */}
                      <div className="bg-black p-2 rounded-lg">
                        <div className="flex items-center justify-between">
                          <div>
                            <h3 className="text-white text-sm font-body font-bold">
                              Jenny Wilson
                            </h3>
                            <div className="flex items-center gap-1 text-primary text-[12px] mt-1">
                              <i className="fas fa-star"></i>
                              <i className="fas fa-star"></i>
                              <i className="fas fa-star"></i>
                              <i className="fas fa-star"></i>
                              <i className="fas fa-star"></i>
                            </div>
                          </div>
                          <img src={testimonail} alt="" className="rounded-lg w-[40%]" />
                        </div>
                        <p className="text-[#DDDDDD] text-sm font-body font-normal mt-3">
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the industry's
                          standard dummy text ever since the 1500s
                        </p>
                      </div>
                      {/* third-card */}
                      <div className="bg-black p-2 rounded-lg">
                        <div className="flex items-center justify-between">
                          <div>
                            <h3 className="text-white text-sm font-body font-bold">
                              Jenny Wilson
                            </h3>
                            <div className="flex items-center gap-1 text-primary text-[12px] mt-1">
                              <i className="fas fa-star"></i>
                              <i className="fas fa-star"></i>
                              <i className="fas fa-star"></i>
                              <i className="fas fa-star"></i>
                              <i className="fas fa-star"></i>
                            </div>
                          </div>
                          <img src={testimonail} alt="" className="rounded-lg w-[40%]" />
                        </div>
                        <p className="text-[#DDDDDD] text-sm font-body font-normal mt-3">
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the industry's
                          standard dummy text ever since the 1500s
                        </p>
                      </div>
                      {/* forth-card */}
                      <div className="bg-black p-2 rounded-lg">
                        <div className="flex items-center justify-between">
                          <div>
                            <h3 className="text-white text-sm font-body font-bold">
                              Jenny Wilson
                            </h3>
                            <div className="flex items-center gap-1 text-primary text-[12px] mt-1">
                              <i className="fas fa-star"></i>
                              <i className="fas fa-star"></i>
                              <i className="fas fa-star"></i>
                              <i className="fas fa-star"></i>
                              <i className="fas fa-star"></i>
                            </div>
                          </div>
                          <img src={testimonail} alt="" className="rounded-lg w-[40%]" />
                        </div>
                        <p className="text-[#DDDDDD] text-sm font-body font-normal mt-3">
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the industry's
                          standard dummy text ever since the 1500s
                        </p>
                      </div>
                    </div>
                  </div>
                </div>)}
              {/* -------------------------------Testimonail-end------------------------- */}
            </div>
          </section>
          {/* ----------------------------mobile-view-start------------------------------------------ */}
          {localStorage.getItem("publicMePassUser") ? (
            <div className="block md:hidden">
              <div className="grid grid-cols-1 gap-5 bg-neutral px-4 py-8">
                {artistView.is_favorite === 'Yes' ? (
                  <button onClick={() => removeToFavorite(artistView.id)} className="border border-secondary tracking-[.25em] py-[8px] px-[16px] rounded-md text-sm text-[#FFD254] font-normal font-body w-full md:w-[260px] lg:w-[301px] inline uppercase">
                    <i className="fal fa-minus ml-2"></i>Remove Favorite
                  </button>
                ) : (
                  <button onClick={() => addToFavorite(artistView.id)} className="border border-secondary tracking-[.25em] py-[8px] px-[16px] rounded-md text-sm text-[#FFD254] font-normal font-body w-full md:w-[260px] lg:w-[301px] inline uppercase">
                    <i className="fas fa-plus mr-3"></i>Add to favorites
                  </button>
                )}
              </div>
            </div>
          ) : ''}

          {/* ----------------------------mobile-view-end------------------------------------------ */}
        </>
      }


    </>
  );
}

export default withRouter(Artiest);
