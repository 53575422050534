import React from "react";
import { Link } from "react-router-dom";

const SkeletonLoaderArtist = () => {
    return (
        <>
            <div className="bg-[#131313] p-3 rounded-lg">
                <div className='flex items-center justify-between gap-5'>
                    <div className="flex-1 bg-slatet h-2 w-full rounded"></div>
                    <div className="bg-slatet rounded-full py-2 px-4 flex-none"></div>
                </div>
                <div className="bg-slatet h-2 w-full rounded mt-2"></div>
                <div className="bg-slatet h-2 w-24 md:w-48 rounded mt-2"></div>
                <div className="bg-slatet rounded-lg mt-4 aspect-square object-cover"></div>
                <div className="bg-slatet h-10 w-full rounded mt-3"></div>
            </div>
        </>
    );
};

export default SkeletonLoaderArtist;