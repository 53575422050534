import React from "react";
import { Link } from "react-router-dom";

const SkeletonLoaderArtiest = () => {
    return (
        <>
            <div className="hidden md:block">
                <div className="w-full h-[360px] bg-slatet pt-28 bg-right md:pt-[135px] pb-20">
                </div>
            </div>
            <div className="md:hidden block">
                <div className="w-full h-[360px] bg-slatet pt-28 bg-right md:pt-[135px] pb-20">
                </div>
            </div>
            <section className="bg-neutral pt-4 pb-6 md:pb-24">
                <div className="container">
                    <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4  items-end gap-4 md:gap-8 -mt-[100px]">
                        <div className="bg-slatet rounded border border-secondary aspect-square"></div>
                        <div className="col-span-1 md:col-span-2 lg:col-span-3 animate-pulse">
                            <div className="block md:hidden">
                                <div class="h-3 bg-slatet rounded w-full flex-1"></div>
                                <div className="bg-slatet rounded-full py-2 px-4 flex-none mt-3">
                                </div>
                            </div>
                            <div className="hidden md:block">
                                <div className="flex gap-4 mb-7">
                                    <div class="h-3 bg-slatet rounded w-full flex-1"></div>
                                    <div className="bg-slatet rounded-full py-2 px-6 flex-none">
                                    </div>
                                </div>
                                <div className="block md:flex items-center gap-4 md:gap-6">
                                    <div className="bg-slatet py-6 px-24 rounded-full "></div>
                                    <div className="bg-slatet py-6 px-24 rounded-full "></div>
                                </div>
                                <div className="flex items-center justify-between gap-6 mt-5">
                                    <ul className="flex items-center gap-2 lg:gap-4">
                                        <li>
                                            <div className="bg-slatet rounded-sm w-[40px] h-[40px] lg:w-[48px] lg:h-[48px] flex"></div>
                                        </li>
                                        <li>
                                            <div className="bg-slatet rounded-sm w-[40px] h-[40px] lg:w-[48px] lg:h-[48px] flex"></div>
                                        </li>
                                        <li>
                                            <div className="bg-slatet rounded-sm w-[40px] h-[40px] lg:w-[48px] lg:h-[48px] flex"></div>
                                        </li>
                                        <li>
                                            <div className="bg-slatet rounded-sm w-[40px] h-[40px] lg:w-[48px] lg:h-[48px] flex"></div>
                                        </li>
                                    </ul>
                                    <div className="h-10 bg-slatet rounded w-48 mt-3 block"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="md:hidden block mt-5 animate-pulse">
                        <div className="flex items-start justify-between gap-3 mt-3 bg-black rounded-lg p-3">
                            <div>
                                <div className="bg-slatet py-4 px-20 rounded-full "></div>
                                <div className="bg-slatet py-4 px-20 rounded-full mt-3"></div>
                            </div>
                            <div>
                                <div className="h-4 bg-slatet rounded-full w-full"></div>
                                <ul className="flex items-center gap-2 mt-5">
                                    <li>
                                        <div className="bg-slatet rounded-sm w-[25px] h-[25px] flex"></div>
                                    </li>
                                    <li>
                                        <div className="bg-slatet rounded-sm w-[25px] h-[25px] flex"></div>
                                    </li>
                                    <li>
                                        <div className="bg-slatet rounded-sm w-[25px] h-[25px] flex"></div>
                                    </li>
                                    <li>
                                        <div className="bg-slatet rounded-sm w-[25px] h-[25px] flex"></div>
                                    </li>

                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default SkeletonLoaderArtiest;
