import React from "react"
import withRouter from "../../components/Common/withRouter"
import img from "../../assets/images/image-404.png"
import { Link } from "react-router-dom"

const Page404 = props => {


    return (
        <>
            <div className="text-center py-24">
                <img src={img} alt="404" className="inline-block" />
                <h2 className="text-[#565872] text-lg font-normal mt-9">Opps! Page Not Found</h2>
                <Link to={'/home'} className="py-[12px] px-[24px] inline-block bg-[#D1F4FF] text-[#15A2CE] text-base font-normal rounded-[4px] mt-6">Go to Home</Link>
            </div>
        </>
    )
}

export default withRouter(Page404)
