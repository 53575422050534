import PropTypes from "prop-types";
import React from "react";
import withRouter from "../../components/Common/withRouter";
import { useSelector } from "react-redux";
// Layout Related Components

const LayoutWithoutHeaderFooter = props => {
  const { loading } = useSelector(state => ({
    loading: state.AppMaster.loading,
  }));
  return (
    <React.Fragment>
      {props.children}
    </React.Fragment>
  );
};

LayoutWithoutHeaderFooter.propTypes = {
  children: PropTypes.any,
};

export default withRouter(LayoutWithoutHeaderFooter);
