import { isEmpty } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from 'react-router-dom';
import logo from "../../assets/images/default/organiser-logo.png";
import banner from "../../assets/images/mobile/artist-banner.png";
import banner2 from '../../assets/images/organiser-banner.png';
import icon from "../../assets/images/small/star-yellow.png";
import testimonail from '../../assets/images/small/testimonail-logo.png';
import { numberFormatter } from "../../common/commonFunction";
import StarRating from "../../components/Common/StarRating";
import Artist from '../../components/Event/Details/Artist';
import EventView from "../../components/Event/View";
import SkeletonLoaderOrganiserDetails from "../../components/SkeletonLoader/Organiser/Details";
import api from "../../constants/api";
import SeoDetails from "../../seo/SeoDetails";
import { clearData, getData } from "../../store/AppMaster/actions";

const OrganiserDetails = props => {
    const [activeButton, setActiveButton] = useState(1);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const params = useParams();

    const { data } = useSelector(state => ({
        data: state.AppMaster.data,
    }));
    const { loading } = useSelector(state => ({
        loading: state.AppMaster.loading,
    }));
    const [profileData, setProfileData] = useState([]);
    // const [artistList, setArtistList] = useState([]);
    const [artists, setArtists] = useState(null);
    const [profileFlag, setProfileFlag] = useState(false)
    const [organiser, setOrganiser] = useState(null);
    const [ratingUser, setRatingUser] = useState({});
    const [eventDetail, setEventDetail] = useState(null);
    const [isEventData, setIsEventData] = useState([]);
    const [isApiCall, setIsApiCall] = useState(0);
    const [isOrganiserDataGet, setIsOrganiserDataGet] = useState(false);
    const [isUpcomingEventData, setIsUpcomingEventData] = useState(false);
    const [upcomingEventDetail, setUpcomingEventDetail] = useState('');
    const [isArtistDataGet, setIsArtistDataGet] = useState(true);

    const ratings = [1, 2, 3, 4, 5];

    // Calculate total ratings from ratingUser
    const totalRatings = Object.values(ratingUser).reduce((sum, count) => sum + count, 0);

    // Function to handle button click and change active button
    const handleButtonClick = (buttonId) => {
        setActiveButton(buttonId);
        if (buttonId === 1) {
            upComingEventDataGet(); // Fetch upcoming events
        } else {
            eventDataGet(); // Fetch past events
        }
    };

    const organiserDataGet = useCallback(() => {
        setIsOrganiserDataGet(true);
        let requestData = {
            merchant_company_slug: params.organiser_slug,
        };
        dispatch(clearData());
        dispatch(getData(requestData, "", api.OrganiserDetails));
    });

    const eventDataGet = useCallback(() => {
        setIsEventData(true);

        let requestData = {
            merchant_company_slug: params.organiser_slug,
            events: 'Past'
        };
        dispatch(clearData());
        dispatch(getData(requestData, "", api.EventList));
    });

    const upComingEventDataGet = useCallback(() => {
        setIsUpcomingEventData(true);
        let requestData = {
            merchant_company_slug: params.organiser_slug,
            events: 'Upcoming'
        };
        dispatch(clearData());
        dispatch(getData(requestData, "", api.EventList));
    });

    const artistDataGet = useCallback(() => {
        setIsArtistDataGet(true);
        let requestData = {
            merchant_company_slug: params.organiser_slug,
        }
        dispatch(clearData());
        dispatch(getData(requestData, "", api.EventArtistList));
    });



    useEffect(() => {
        if (isApiCall === 0) {
            organiserDataGet();
            upComingEventDataGet();
            artistDataGet();
            setIsApiCall(1);
        }
    }, [isApiCall]);

    useEffect(() => {
        if (!isEmpty(data) && !isEmpty(data.userView) && isOrganiserDataGet) {
            setOrganiser(data.userView);
            setIsOrganiserDataGet(false);
            setRatingUser(data.userView.ratingUser);
        }
        if (!isEmpty(data) && Array.isArray(data.eventList)) {
            if (activeButton === 1) {
                setEventDetail(data.eventList); // Update past events
            } else {
                setUpcomingEventDetail(data.eventList); // Update upcoming events
            }
        }
        if (!isEmpty(data) && !isEmpty(data.profileView) && profileFlag) {
            setProfileData(data.profileView)

        }

        if (!isEmpty(data) && !isEmpty(data.artistList) && isArtistDataGet) {
            setArtists(data.artistList);
            setIsArtistDataGet(false);
        }
    }, [data, activeButton]);

    const testimonialEnabled = process.env.REACT_APP_ARTIST_VIEW_TESTIMONIAL === 'true';

    return (
        <>
            <SeoDetails title={organiser && organiser.merchant_company_name + ' | mepass.in'}
                description={organiser && organiser.short_description}
                ogImage={organiser && organiser.logo} />

            {loading && <SkeletonLoaderOrganiserDetails />}
            {!loading && (
                <>
                    <div className='hidden md:block'>
                        <div className="w-full h-[380px] pt-28 md:pt-[135px] pb-20 " >
                            <div
                                className="absolute inset-0 -z-10"
                                style={{
                                    backgroundImage: `url(${organiser && organiser.cover_image ? organiser.cover_image : banner2})`,
                                    filter: 'blur(15px)',
                                    opacity: '0.5px',
                                    backgroundSize: 'cover',
                                    backgroundRepeat: 'no-repeat',
                                    backgroundPosition: 'center',
                                }}
                            ></div>
                             <div
                            className="absolute inset-0 bg-black -z-10"
                            style={{ opacity: 0.5 }}
                        ></div>
                        </div>
                    </div>
                    <div className="md:hidden block">
                        <div
                            className="w-full h-[300px] pt-28 bg-right md:pt-[135px] pb-20 "
                        >
                            <div
                                className="absolute inset-0 -z-10"
                                style={{
                                    backgroundImage: `url(${organiser && organiser.cover_image ? organiser.cover_image : banner2})`,
                                    filter: 'blur(15px)',
                                    opacity: '0.5px',
                                    backgroundSize: 'cover',
                                    backgroundRepeat: 'no-repeat',
                                    backgroundPosition: 'center',
                                }}
                            ></div>
                             <div
                            className="absolute inset-0 bg-black -z-10"
                            style={{ opacity: 0.5 }}
                        ></div>
                        </div>
                    </div>
                    <section className='bg-neutral pt-14 pb-24'>
                        <div className="container">
                            {/* -------------------------------section-start------------------------- */}
                            <div className='flex items-end -mt-[150px]'>
                                <div className='flex-none w-2/4 md:w-[40%] lg:w-1/4'>
                                    {organiser && organiser.logo &&
                                        <div className='bg-white p-5 rounded-lg max-h-full md:max-h-80 flex items-center justify-center border border-secondary'>
                                            <img src={organiser.logo ? organiser.logo : logo} alt="" />
                                        </div>
                                    }

                                </div>
                                <div className='flex-1 w-2/4 md:w-3/4 ml-6'>
                                    {organiser && organiser.merchant_company_name &&
                                        <h2 className='text-base md:text-2xl lg:text-3xl font-body font-medium text-white mb-0 md:mb-5'>
                                            {organiser.merchant_company_name}
                                        </h2>
                                    }
                                    <div className='block md:hidden'>
                                        {organiser && organiser.rating > 0 && totalRatings > 0 && (
                                            <div className="bg-primary flex items-center justify-center w-[72px] h-[32px] rounded-full mt-3 md:mt-0">
                                                <img src={icon} alt="" className="inline-block mr-1" />
                                                {organiser && organiser.rating &&
                                                    <span className="text-white text-sm font-body font-bold">
                                                        {organiser.rating}
                                                    </span>
                                                }
                                            </div>
                                        )}
                                    </div>
                                    <div className='hidden md:block'>
                                        <div className='flex items-center gap-5 lg:gap-10 mt-3'>
                                            {organiser && organiser.fanbase &&
                                                <div className='py-[10px] px-[20px] lg:px-[30px] rounded-full md:text-md 2xl:text-xl font-body font-normal text-white border border-white'>
                                                    <i className="fas fa-user-friends text-lg align-middle mr-1"></i> Fan base : <span className='font-semibold'>{numberFormatter(organiser.fanbase)} </span>
                                                </div>
                                            }
                                            {organiser && organiser.total_event &&
                                                <div className='py-[10px] px-[30px] rounded-full md:text-md 2xl:text-xl font-body font-normal text-white border border-white'>
                                                    <i className="fas fa-star text-lg align-middle mr-1"></i> Events : <span className='font-semibold'>{numberFormatter(organiser.total_event)} </span>
                                                </div>
                                            }
                                        </div>
                                        <ul className='flex items-center gap-4 mt-7'>
                                            {organiser && organiser.facebook_url &&
                                                <Link to={organiser.facebook_url} target="_blank" rel="noopener noreferrer">
                                                    <li>
                                                        <div className='bg-white rounded-sm text-primary w-[48px] h-[48px] flex items-center justify-center'>
                                                            <i className="fab fa-facebook-f text-2xl"></i>
                                                        </div>
                                                    </li>
                                                </Link>
                                            }
                                            {organiser && organiser.instagram_url &&
                                                <Link to={organiser.instagram_url} target="_blank" rel="noopener noreferrer">
                                                    <li>
                                                        <div className='bg-white rounded-sm text-primary w-[48px] h-[48px] flex items-center justify-center'>
                                                            <i className="fab fa-instagram text-2xl"></i>
                                                        </div>
                                                    </li>
                                                </Link>
                                            }
                                            {organiser && organiser.youtube_url &&
                                                <Link to={organiser.youtube_url} target="_blank" rel="noopener noreferrer">
                                                    <li>
                                                        <div className='bg-white rounded-sm text-primary w-[48px] h-[48px] flex items-center justify-center'>
                                                            <i className="fab fa-youtube text-2xl"></i>
                                                        </div>
                                                    </li>
                                                </Link>
                                            }
                                            {organiser && organiser.instagram_url &&
                                                <Link to={organiser.instagram_url} target="_blank" rel="noopener noreferrer">
                                                    <li>
                                                        <div className='bg-white rounded-sm text-primary w-[48px] h-[48px] flex items-center justify-center'>
                                                            <i className="fab fa-instagram-square text-2xl"></i>
                                                        </div>
                                                    </li>
                                                </Link>
                                            }
                                        </ul>

                                    </div>
                                </div>
                            </div>
                            {(organiser && (organiser.fanbase || organiser.total_event)) && (

                                <div className='md:hidden block mt-5'>
                                    <div className='flex items-center justify-between gap-3 mt-3 bg-black rounded-lg p-3'>
                                        <div>
                                            {organiser && organiser.fanbase &&
                                                <div className='py-1 px-3 rounded-full text-md font-body font-normal text-white border border-white'>
                                                    <i className="fas fa-user-friends text-sm align-middle mr-1"></i> Fan base : <span className='font-semibold'>{numberFormatter(organiser.fanbase)} </span>
                                                </div>
                                            }
                                            {organiser && organiser.total_event &&
                                                <div className='py-1 px-3 rounded-full text-md font-body font-normal text-white border border-white mt-3'>
                                                    <i className="fas fa-star text-sm align-middle mr-1"></i> Events : <span className='font-semibold'>{numberFormatter(organiser.total_event)} </span>
                                                </div>
                                            }
                                        </div>
                                        {(organiser && (organiser.facebook_url || organiser.instagram_url || organiser.youtube_url)) && (

                                            <div>
                                                <span className='text-white text-base font-body font-bold'>Follow</span>
                                                <ul className='flex items-center gap-2 mt-2'>
                                                    {organiser && organiser.facebook_url &&
                                                        <Link to={organiser.facebook_url} target="_blank" rel="noopener noreferrer">
                                                            <li>
                                                                <div className='bg-white rounded-sm text-primary w-[25px] h-[25px] flex items-center justify-center'>
                                                                    <i className="fab fa-facebook-f text-md"></i>
                                                                </div>
                                                            </li>
                                                        </Link>
                                                    }
                                                    {organiser && organiser.instagram_url &&
                                                        <Link to={organiser.instagram_url} target="_blank" rel="noopener noreferrer">
                                                            <li>
                                                                <div className='bg-white rounded-sm text-primary w-[25px] h-[25px] flex items-center justify-center'>
                                                                    <i className="fab fa-instagram text-md"></i>
                                                                </div>
                                                            </li>
                                                        </Link>
                                                    }
                                                    {organiser && organiser.youtube_url &&
                                                        <Link to={organiser.youtube_url} target="_blank" rel="noopener noreferrer">
                                                            <li>
                                                                <div className='bg-white rounded-sm text-primary w-[25px] h-[25px] flex items-center justify-center'>
                                                                    <i className="fab fa-youtube text-md"></i>
                                                                </div>
                                                            </li>
                                                        </Link>
                                                    }
                                                    {organiser && organiser.instagram_url &&
                                                        <Link to={organiser.instagram_url} target="_blank" rel="noopener noreferrer">
                                                            <li>
                                                                <div className='bg-white rounded-sm text-primary w-[25px] h-[25px] flex items-center justify-center'>
                                                                    <i className="fab fa-instagram-square text-md"></i>
                                                                </div>
                                                            </li>
                                                        </Link>
                                                    }
                                                </ul>
                                            </div>
                                        )}

                                    </div>

                                </div>
                            )}
                            {/* -------------------------------section-end------------------------- */}

                            {/* -------------------------------event-hastage-start------------------------- */}
                            {/* <div className='pt-14'>
                        <h2 className='text-xl md:text-3xl lg:text-[40px] font-body font-light tracking-[0.2rem] text-white uppercase mb-4 hidden md:block'>About organiser</h2>
                        <h2 className='text-xl font-body font-light tracking-[0.2rem] text-white uppercase mb-4 md:hidden block'>About Event</h2>
                        {organiser && organiser.description &&
                            <p className='text-[#DCDCDC] text-base font-body font-normal'>{organiser.description}</p>
                        }
                    </div> */}

                            {/* -------------------------------event-hastage-end------------------------- */}

                            {/* -------------------------------review-start------------------------- */}
                            {organiser && organiser.rating > 0 && totalRatings > 0 && (
                                <div className='pt-14'>
                                    <div className="flex items-center justify-start md:justify-center border-0 md:border border-secondary w-full md:w-[79.5%] p-0 md:p-10 rounded-[20px] m-auto gap-6">
                                        <div className='text-center'>
                                            {organiser.rating && (
                                                <span className='text-white font-body font-normal text-5xl lg:text-[80px]'>
                                                    {organiser.rating}
                                                </span>
                                            )}
                                            <div className='md:hidden block'>
                                                <div className='flex items-center gap-2'>
                                                    {organiser.rating > 0 && <StarRating rating={organiser.rating} />}
                                                </div>
                                            </div>
                                        </div>

                                        <ol className="list-decimal text-[#5A5A5A] ml-6 w-full">
                                            {ratings.map(rating => {
                                                const currentRatingCount = ratingUser[rating] || 0;
                                                const ratingPercentage =
                                                    totalRatings === 0
                                                        ? 0
                                                        : (currentRatingCount / totalRatings) * 100;

                                                return (
                                                    <li key={rating} className={rating > 1 ? "mt-2" : ""}>
                                                        <div className="flex items-center gap-2">
                                                            <i className="fas fa-star text-[#FCB426] text-sm"></i>
                                                            <div className="w-full bg-gray-200 rounded-full h-[4px] lg:h-2">
                                                                <div
                                                                    className="bg-primary h-[4px] lg:h-2 rounded-full"
                                                                    style={{ width: `${ratingPercentage}%` }}
                                                                ></div>
                                                            </div>
                                                        </div>
                                                    </li>
                                                );
                                            })}
                                        </ol>
                                    </div>
                                </div>
                            )}

                            {/* -------------------------------review-end------------------------- */}

                            {/* -----------------------------------section-start---------------------- */}

                            <div className='pt-24'>
                                <div className="bg-[#1B1B1B] p-2 rounded-lg mb-10">
                                    <div className="flex items-center justify-between">
                                        <button
                                            className={`${activeButton === 1 ? 'active-button bg-secondary rounded-md !text-black font-semibold' : ''} py-1 text-white font-body font-normal text-[18px] w-1/2`}
                                            onClick={() => handleButtonClick(1)}
                                        >
                                            Upcoming Events
                                        </button>
                                        <button
                                            className={`${activeButton === 2 ? 'active-button !bg-secondary rounded-md !text-black font-semibold' : ''} bg-transparent py-1 text-white font-body font-normal text-[18px] w-1/2`}
                                            onClick={() => handleButtonClick(2)}
                                        >
                                            Past Events
                                        </button>
                                    </div>
                                </div>
                                <div>
                                    {activeButton === 1 ? (
                                        <div className="grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
                                            {eventDetail && eventDetail.length > 0 ? (
                                                eventDetail && eventDetail.map((singleEvent, index) => ( //past event
                                                    <Link to={"/events/" + singleEvent.slug}>
                                                        <EventView singleEvent={singleEvent} key={index} />
                                                    </Link>
                                                ))
                                            ) : (
                                                <p className="col-span-4 text-white text-center">No upcoming events</p>
                                            )}
                                        </div>
                                        ) : (
                                        <div className="grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
                                         {upcomingEventDetail && upcomingEventDetail.length > 0 ? (
                                            upcomingEventDetail && upcomingEventDetail.map((singleEvent, index) => ( //upcoming event
                                                <Link to={"/events/" + singleEvent.slug} key={index}>
                                                    <EventView singleEvent={singleEvent} />
                                                </Link>
                                            ))
                                        ) : (
                                            <p className="col-span-4 text-white text-center">No past events</p>
                                        )}
                                        </div>
                                    )}
                                </div>
                            </div>


                            {/* ----------------------------------section-end----------------------- */}

                            {/* -------------------------------Artist-Start------------------------- */}

                            <Artist artists={artists} props={props} />

                            {/* -------------------------------Artist-end------------------------- */}

                            {/* -------------------------------Testimonail-Start------------------------- */}
                            {testimonialEnabled && (
                                <div className="pt-10 md:pt-24">
                                    <h2 className="text-2xl md:text-[40px] font-body font-light tracking-[.150em] text-white uppercase text-center">
                                        Testimonial
                                    </h2>
                                    <div className="hidden md:block">
                                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-8 mt-8">
                                            {/* first-card */}
                                            <div className="bg-black p-5 rounded-lg">
                                                <div className="flex items-center justify-between">
                                                    <div>
                                                        <h3 className="text-white text-base font-body font-bold">
                                                            Jenny Wilson
                                                        </h3>
                                                        <div className="flex items-center gap-1 text-primary text-xs mt-2">
                                                            <i className="fas fa-star"></i>
                                                            <i className="fas fa-star"></i>
                                                            <i className="fas fa-star"></i>
                                                            <i className="fas fa-star"></i>
                                                            <i className="fas fa-star"></i>
                                                        </div>
                                                    </div>
                                                    <img src={testimonail} alt="" className="rounded-lg" />
                                                </div>
                                                <p className="text-[#DDDDDD] text-lg font-body font-normal mt-3">
                                                    Lorem Ipsum is simply dummy text of the printing and
                                                    typesetting industry. Lorem Ipsum has been the industry's
                                                    standard dummy text ever since the 1500s
                                                </p>
                                            </div>
                                            {/* secound-card */}
                                            <div className="bg-black p-5 rounded-lg">
                                                <div className="flex items-center justify-between">
                                                    <div>
                                                        <h3 className="text-white text-base font-body font-bold">
                                                            Jenny Wilson
                                                        </h3>
                                                        <div className="flex items-center gap-1 text-primary text-xs mt-2">
                                                            <i className="fas fa-star"></i>
                                                            <i className="fas fa-star"></i>
                                                            <i className="fas fa-star"></i>
                                                            <i className="fas fa-star"></i>
                                                            <i className="fas fa-star"></i>
                                                        </div>
                                                    </div>
                                                    <img src={testimonail} alt="" className="rounded-lg" />
                                                </div>
                                                <p className="text-[#DDDDDD] text-lg font-body font-normal mt-3">
                                                    Lorem Ipsum is simply dummy text of the printing and
                                                    typesetting industry. Lorem Ipsum has been the industry's
                                                    standard dummy text ever since the 1500s
                                                </p>
                                            </div>
                                            {/* third-card */}
                                            <div className="bg-black p-5 rounded-lg">
                                                <div className="flex items-center justify-between">
                                                    <div>
                                                        <h3 className="text-white text-base font-body font-bold">
                                                            Jenny Wilson
                                                        </h3>
                                                        <div className="flex items-center gap-1 text-primary text-xs mt-2">
                                                            <i className="fas fa-star"></i>
                                                            <i className="fas fa-star"></i>
                                                            <i className="fas fa-star"></i>
                                                            <i className="fas fa-star"></i>
                                                            <i className="fas fa-star"></i>
                                                        </div>
                                                    </div>
                                                    <img src={testimonail} alt="" className="rounded-lg" />
                                                </div>
                                                <p className="text-[#DDDDDD] text-lg font-body font-normal mt-3">
                                                    Lorem Ipsum is simply dummy text of the printing and
                                                    typesetting industry. Lorem Ipsum has been the industry's
                                                    standard dummy text ever since the 1500s
                                                </p>
                                            </div>
                                            {/* forth-card */}
                                            <div className="bg-black p-5 rounded-lg">
                                                <div className="flex items-center justify-between">
                                                    <div>
                                                        <h3 className="text-white text-base font-body font-bold">
                                                            Jenny Wilson
                                                        </h3>
                                                        <div className="flex items-center gap-1 text-primary text-xs mt-2">
                                                            <i className="fas fa-star"></i>
                                                            <i className="fas fa-star"></i>
                                                            <i className="fas fa-star"></i>
                                                            <i className="fas fa-star"></i>
                                                            <i className="fas fa-star"></i>
                                                        </div>
                                                    </div>
                                                    <img src={testimonail} alt="" className="rounded-lg" />
                                                </div>
                                                <p className="text-[#DDDDDD] text-lg font-body font-normal mt-3">
                                                    Lorem Ipsum is simply dummy text of the printing and
                                                    typesetting industry. Lorem Ipsum has been the industry's
                                                    standard dummy text ever since the 1500s
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="md:hidden block">
                                        <div className="grid grid-cols-2 gap-4 mt-8">
                                            {/* first-card */}
                                            <div className="bg-black p-2 rounded-lg">
                                                <div className="flex items-center justify-between">
                                                    <div>
                                                        <h3 className="text-white text-sm font-body font-bold">
                                                            Jenny Wilson
                                                        </h3>
                                                        <div className="flex items-center gap-1 text-primary text-[12px] mt-1">
                                                            <i className="fas fa-star"></i>
                                                            <i className="fas fa-star"></i>
                                                            <i className="fas fa-star"></i>
                                                            <i className="fas fa-star"></i>
                                                            <i className="fas fa-star"></i>
                                                        </div>
                                                    </div>
                                                    <img src={testimonail} alt="" className="rounded-lg w-[40%]" />
                                                </div>
                                                <p className="text-[#DDDDDD] text-sm font-body font-normal mt-3">
                                                    Lorem Ipsum is simply dummy text of the printing and
                                                    typesetting industry. Lorem Ipsum has been the industry's
                                                    standard dummy text ever since the 1500s
                                                </p>
                                            </div>
                                            {/* secound-card */}
                                            <div className="bg-black p-2 rounded-lg">
                                                <div className="flex items-center justify-between">
                                                    <div>
                                                        <h3 className="text-white text-sm font-body font-bold">
                                                            Jenny Wilson
                                                        </h3>
                                                        <div className="flex items-center gap-1 text-primary text-[12px] mt-1">
                                                            <i className="fas fa-star"></i>
                                                            <i className="fas fa-star"></i>
                                                            <i className="fas fa-star"></i>
                                                            <i className="fas fa-star"></i>
                                                            <i className="fas fa-star"></i>
                                                        </div>
                                                    </div>
                                                    <img src={testimonail} alt="" className="rounded-lg w-[40%]" />
                                                </div>
                                                <p className="text-[#DDDDDD] text-sm font-body font-normal mt-3">
                                                    Lorem Ipsum is simply dummy text of the printing and
                                                    typesetting industry. Lorem Ipsum has been the industry's
                                                    standard dummy text ever since the 1500s
                                                </p>
                                            </div>
                                            {/* third-card */}
                                            <div className="bg-black p-2 rounded-lg">
                                                <div className="flex items-center justify-between">
                                                    <div>
                                                        <h3 className="text-white text-sm font-body font-bold">
                                                            Jenny Wilson
                                                        </h3>
                                                        <div className="flex items-center gap-1 text-primary text-[12px] mt-1">
                                                            <i className="fas fa-star"></i>
                                                            <i className="fas fa-star"></i>
                                                            <i className="fas fa-star"></i>
                                                            <i className="fas fa-star"></i>
                                                            <i className="fas fa-star"></i>
                                                        </div>
                                                    </div>
                                                    <img src={testimonail} alt="" className="rounded-lg w-[40%]" />
                                                </div>
                                                <p className="text-[#DDDDDD] text-sm font-body font-normal mt-3">
                                                    Lorem Ipsum is simply dummy text of the printing and
                                                    typesetting industry. Lorem Ipsum has been the industry's
                                                    standard dummy text ever since the 1500s
                                                </p>
                                            </div>
                                            {/* forth-card */}
                                            <div className="bg-black p-2 rounded-lg">
                                                <div className="flex items-center justify-between">
                                                    <div>
                                                        <h3 className="text-white text-sm font-body font-bold">
                                                            Jenny Wilson
                                                        </h3>
                                                        <div className="flex items-center gap-1 text-primary text-[12px] mt-1">
                                                            <i className="fas fa-star"></i>
                                                            <i className="fas fa-star"></i>
                                                            <i className="fas fa-star"></i>
                                                            <i className="fas fa-star"></i>
                                                            <i className="fas fa-star"></i>
                                                        </div>
                                                    </div>
                                                    <img src={testimonail} alt="" className="rounded-lg w-[40%]" />
                                                </div>
                                                <p className="text-[#DDDDDD] text-sm font-body font-normal mt-3">
                                                    Lorem Ipsum is simply dummy text of the printing and
                                                    typesetting industry. Lorem Ipsum has been the industry's
                                                    standard dummy text ever since the 1500s
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>)}
                            {/* -------------------------------Testimonail-end------------------------- */}


                        </div>
                    </section>

                </>
            )}

        </>
    );
}

export default OrganiserDetails;
