
import React from "react";
import { Link, NavLink } from "react-router-dom";
import logo from "../../assets/images/logo.png";
import { isEmpty } from "lodash";

const SidebarMenu = ({ sidebarArray, toggleSubmenu, handleActiveItem, activeItem, openSubmenu, isResponsive, handleSidebarToggle, sidebarToggle, handleNavLinkClick, toggleSubmenuIndex, openSubmenuIndex }) => {

    return (
        <>
            {isResponsive ? (
                <>
                    <aside
                        className={`fixed right-0 top-0 z-30 overflow-y-auto h-full xl:hidden bg-white block flex-col 
      transition-transform duration-300 ease-linear
      ${sidebarToggle ? "translate-x-0 w-[252px]" : "translate-x-[252px] w-[252px]"}`}
                    >
                        <div className="flex flex-col overflow-y-auto">
                            <div className="flex items-center justify-between border-b p-3">
                                <Link to="/home">
                                    <img className="w-[40px]" src={logo} alt="Your Company" />
                                </Link>
                                <div
                                    className="size-6 bg-primary rounded-full text-white text-center cursor-pointer"
                                    onClick={handleNavLinkClick}
                                >
                                    <i className="fas fa-times mt-1"></i>
                                </div>
                            </div>
                            <nav className="py-[10px] px-[20px]">
                                <div>
                                    <ul className="mb-6 flex flex-col gap-1.5">
                                        {sidebarArray.map((item, index) => (
                                            <li className="mb-[10px]" key={item.name + index}>
                                                <div>
                                                    {item.submenu && item.submenu.length > 0 ?
                                                        <Link
                                                            to={item.link}
                                                            className={`flex items-center rounded-md ${activeItem == item.link ? "text-primary" : "text-black"} w-[212px] h-[34px] p-[10px] justify-start`}
                                                            onClick={() => {
                                                                handleActiveItem(item.link);
                                                                toggleSubmenu(index);
                                                            }}
                                                        >
                                                            <div className="text-dark text-[16px] font-normal font-body ml-2">
                                                                {item.name}
                                                                {item.submenu && item.submenu && item.submenu.length > 0 && (
                                                                    <button
                                                                        onClick={() => toggleSubmenuIndex(index)}
                                                                        className="text-sm ml-4 text-gray-500"
                                                                    >
                                                                        <i className="fas fa-caret-down"></i>
                                                                    </button>
                                                                )}
                                                            </div>
                                                        </Link>
                                                        :
                                                        <Link
                                                            to={item.link}
                                                            className={`flex items-center rounded-md ${activeItem == item.link ? "text-primary" : "text-black"} w-[212px] h-[34px] p-[10px] justify-start`}
                                                            onClick={() => {
                                                                handleActiveItem(item.link);
                                                            }}
                                                        >
                                                            <div className="text-dark text-[16px] font-normal font-body ml-2">
                                                                {item.name}
                                                            </div>
                                                        </Link>
                                                    }

                                                    {/* Toggle Submenu */}

                                                </div>
                                                {/* Render Submenu */}
                                                {item.submenu && item.submenu && openSubmenu === index && (
                                                    <ul className="ml-6 mt-2">
                                                        {item.submenu && item.submenu.map((subItem, subIndex) => (
                                                            <li key={subItem.name + subIndex} className="mb-[8px]">
                                                                <Link
                                                                    to={subItem.link}
                                                                    className={`flex items-center rounded-md`}
                                                                >
                                                                    <div className="text-black text-[14px] font-normal font-body ml-2">
                                                                        {subItem.name}
                                                                    </div>
                                                                </Link>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                )}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </nav>
                        </div>
                    </aside>


                    <div className="block xl:hidden ml-4">
                        <i
                            className="fas fa-bars text-white text-xl cursor-pointer"
                            onClick={handleSidebarToggle} // Toggle sidebar on click
                        ></i>
                    </div>
                </>
            ) : (
                <ul className="flex items-center group">
                    {sidebarArray.map((item, index) => (
                        <li key={item.name + index} onClick={() => toggleSubmenu(index)}
                            className={` text-[16px] rounded-md px-4 py-2 font-normal font-body hover:text-secondary relative ${activeItem === item.link ? 'text-secondary' : 'text-white'}`}>
                            {item.submenu && item.submenu.length > 0 ? (
                                <Link
                                    onClick={() => {
                                        toggleSubmenu(index);
                                        handleActiveItem(item.link);
                                    }}

                                >
                                    {item.name}
                                    {item.submenu && item.submenu.length > 0 && (
                                        <i className="fas fa-caret-down ml-1"></i>
                                    )}
                                </Link>
                            )
                                :
                                <Link
                                    to={item.link}
                                    onClick={() => {
                                        handleActiveItem(item.link);
                                    }}
                                >
                                    {item.name}
                                </Link>
                            }
                            {item.submenu && item.submenu.length > 0 && openSubmenu === index && (
                                <ul className="absolute top-full right-0 mt-2 w-[224px] bg-white text-black rounded-lg shadow-lg max-h-[300px] overflow-auto p-4">
                                    {item.submenu && item.submenu.map((subItem, subIndex) => (
                                        <>
                                            <li key={subItem.name + subIndex} className="cursor-pointer text-base font-normal text-black">
                                                <Link to={subItem.link}>
                                                    {subItem.name}
                                                </Link>
                                            </li>
                                            {subIndex !== item.submenu.length - 1 && (
                                                <div className="border-b border-[#CACED8] mb-3 pb-3"></div>
                                            )}
                                        </>
                                    ))}
                                </ul>
                            )}
                        </li>
                    ))}
                </ul >
            )}

        </>
    );
};

export default SidebarMenu;
