import { useFormik } from "formik";
import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import api from "../../constants/api";
import { addUpdateNewData, getData } from "../../store/AppMaster/actions";
import { convertToAmPm } from "../../common/commonFunction";

const QrPassImage = ({
    passDetails,
    RFIDImage,
    ExpiredImage,
    DeletedImage,
    ScannedImage
}) => {

    return (

        <>
            {(passDetails && passDetails.status !== null && (passDetails.status === "Expired" || passDetails.status === "Deleted" || passDetails.status === 'Scann' || passDetails.available_quantity === 0)) ? (
                <>
                    {passDetails && passDetails.event_pass && passDetails.event_pass.is_rfid == 'Yes' ? (
                        <img src={RFIDImage} alt="img" className="inline-block my-4" />
                    ) : passDetails.status === "Expired" ? (
                        <img src={ExpiredImage} alt="img" className="inline-block my-4" />
                    ) : passDetails.status === "Deleted" ? (
                        <img src={DeletedImage} alt="img" className="inline-block my-4" />
                    ) : passDetails.status === "Scann" ? (
                        <img src={ScannedImage} alt="img" className="inline-block my-4" />
                    ) : passDetails.available_quantity === 0 ? (
                        <img src={DeletedImage} alt="img" className="inline-block my-4" />
                    ) : ''}
                </>
            ) : passDetails && passDetails.event_pass && passDetails.event_pass.is_rfid == 'Yes' ?
                <img src={RFIDImage} alt="img" className="inline-block my-4" />
                :
                <img src={passDetails && passDetails.qr_image} alt="img" className="inline-block my-4" />
            }
        </>

    )
}
QrPassImage.propTypes = {

}
export default QrPassImage
