import PropTypes from "prop-types";
import React from "react";
import withRouter from "../../components/Common/withRouter";
import { useLocation } from 'react-router-dom';
// Layout Related Components
import Footer from "../Layout/Footer";
import ComingSoonFooter from "../Layout/ComingSoonFooter";

const Layout = props => {
  const location = useLocation();
  const currentRoute = location.pathname;
  return (
    <React.Fragment>
      {props.children}
      {(currentRoute === '/coming-soon' || currentRoute === '/')
        ? <ComingSoonFooter />
        : <Footer />}
    </React.Fragment>
  );
};

Layout.propTypes = {
  children: PropTypes.any,
};

export default withRouter(Layout);
