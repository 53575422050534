import React from "react";
import { Link } from "react-router-dom";

const SkeletonLoaderBanner = () => {
    return (
        <>
            <div className="relative w-full animate-pulse min-h-[620px] md:min-h-full md:h-full bg-cover pt-28 md:pt-[135px] pb-10 md:pb-20">
                <div
                    className="absolute inset-0 -z-10"
                    style={{
                        backgroundImage: "",
                        filter: "blur(30px)",
                        opacity: "0.5",
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",
                    }}
                ></div>
                <div className="absolute inset-0 bg-black -z-10" style={{ opacity: 0.5 }}></div>

                <div className="container">
                    <div className="block md:hidden mb-3">
                        <div className="flex items-center justify-between gap-5">
                            <h1 className="flex-1 bg-slatet h-2 w-full"></h1>
                            <div className="bg-slatet rounded-full py-2 px-4 flex-none"></div>
                        </div>
                    </div>
                    <div className="block md:hidden">
                        <Link to="">
                            <div class="h-2 bg-slatet rounded"></div>
                        </Link>
                    </div>
                    <div className="grid grid-cols-2 xl:grid-cols-3 gap-5 md:gap-10 lg:gap-32 mt-5 md:mt-0 items-end md:items-center">
                        <div className="col-span-1 xl:col-span-2">
                            <div className="hidden md:block">
                                <div className="flex items-center justify-between gap-5">
                                    <div class="h-3 bg-slatet rounded w-full flex-1"></div>
                                    <div className="bg-slatet rounded-full py-2 px-4 flex-none">
                                    </div>
                                </div>
                            </div>
                            <Link to="">
                                <div class="h-3 bg-slatet rounded w-full hidden md:block mt-3"></div>
                            </Link>
                            <div className="block md:flex gap-5 md:pt-5">
                                <div class="h-3 bg-slatet rounded w-full md:w-48"></div>
                                <div class="h-3 bg-slatet rounded w-full md:w-48 mt-2 md:mt-0"></div>
                            </div>
                            <div class="h-3 bg-slatet rounded w-full md:w-48 block md:hidden mt-2"></div>
                            <div class="h-3 bg-slatet rounded w-full md:w-48  mt-2 md:mt-4"></div>
                            <div className="md:block hidden mt-3">
                                <div class="h-10 bg-slatet rounded w-full md:w-48 inline-flex mr-2"></div>
                                <div class="h-10 bg-slatet rounded w-full md:w-48 inline-flex"></div>
                            </div>

                            <div className="flex justify-between mt-4 md:mt-5">
                                <div class="h-3 bg-slatet rounded w-12 md:w-24 xl:w-48 flex-none"></div>
                                <div className="text-end">
                                    <div className="flex justify-end flex-1">
                                        <div class="h-3 bg-slatet rounded w-12 md:w-24 xl:w-48 mr-2 md:mr-3 "></div>
                                        <div class="h-3 bg-slatet rounded w-12 md:w-24 xl:w-48"></div>
                                    </div>
                                    <div class="h-3 bg-slatet rounded w-12 md:w-24 xl:w-48 ml-auto mt-2"></div>
                                </div>
                            </div>

                            <div className="flex items-center gap-2 mt-5">
                                <div class="h-10 bg-slatet rounded w-full md:w-48 inline-flex"></div>
                            </div>
                        </div>

                        <div>
                            <Link to="">
                                <div className="bg-slatet w-full rounded-xl aspect-[9/16]"></div>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default SkeletonLoaderBanner;